import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  formDetail: {
    // borderColor: '1px',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    width: '1000px',
    margin: '0 auto 70px auto',
    // paddingLeft: '50px',
    // paddingRight: '50px',
  },
  avatarCover: {
    width: theme.spacing(26),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: '100%',
    height: theme.spacing(26),
    // img : {
      cursor: 'pointer'
    // }
  },
  spanAvatar: {
    fontSize: '18px'
  },
  buttonDetailKOL: {
    marginTop: '20px',
    display: 'block',
    padding: '10px 10px',
    backgroundColor: 'blue',
    color: 'white'
  },
  imgBorder: {
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: '1px',
    cursor: 'pointer'
  },
  cardIDCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  portraitCover: {
    width: '80%', 
    margin: 'auto',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // Paper for Lightbox
  paper: {
    position: 'absolute',
    minWidth: 400,
    maxWidth: 1500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      width: '100%'
    }
  },
  lightbox: {
    width: 'auto',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  imageListCover: {
    position: 'relative',
    width: '85%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
    '&::after': {
      content: '"List KOL Applied"',
      display: 'block',
      // width: '150px',
      backgroundColor: 'white',
      position: 'absolute',
      top: `0%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      fontSize: '20px',
      padding: '0px 5px'
    }
  },
  imageList: {
    padding: '20px 20px 0px 20px',
    width: '100%',
    minHeight: '64px',
    border: '2px dashed grey',
    borderRadius: '5px',
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  imageCover: {
    width: '60px',
    height: '60px',
    margin: '0px 5px 20px 5px',

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '100%'
    }
  },
  chipSuggestCover: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  chipSuggest: {
    marginLeft: '4px',
    marginRight: '4px',
    paddingTop: '18px',
    paddingBottom: '18px',
    fontSize: '14px'
  },
  chipContainerOverride: {
    '& .MuiInputBase-root': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  divBox: {
    position: 'fixed',
    width: '100%',
    height: '65px',
    backgroundColor: 'white',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: '0px 0px 5px #aaaaaa',
    'z-index': 10,
    '& Button': {
      height: '46px',
      width: '140px',
      // padding: '0px 20px'
    }
  },
  buttonSave: {
    marginRight: '200px'
  },
  buttonApprove: {
    marginRight: '30px',
    backgroundColor: '#28a745',
    '&:hover': {
      backgroundColor: '#357a38'
    }
  },
  buttonCancel: {
    marginRight: '30px',
    backgroundColor: '#dc3545',
    '&:hover': {
      backgroundColor: '#c82234'
    }
  },
  customDialog: {
    '& .MuiDialog-paper': {
      width: '480px',
      boxShadow: '0px 0px 15px gray'
    }
  },
  listCandidates: {
    position: 'relative',
    width: '80%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
    border: '2px solid lightgray',
    borderRadius: '10px',
    '&::after': {
      content: '"Candidates"',
      display: 'block',
      // width: '150px',
      backgroundColor: 'white',
      position: 'absolute',
      top: `0%`,
      left: `50%`,
      transform: `translate(-50%, -100%)`,
      fontSize: '20px',
      padding: '5px 20px',
      borderBottom: '2px solid lightgray',
      // borderTopLeftRadius: '10px',
      // borderTopRightRadius: '10px',
    }
  }
}))

