import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  Avatar,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Chip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { sendNotification } from '../../../../components/Notification/Notification';

//css
import { useStyles } from './style';

// service
import * as SettingService from '../../../../services/setting.service';
import * as PhoneSettingService from '../../../../services/phoneSetting.service';

// icon
import {
  VisibilityOutlined as EyeIcon,
  CheckCircleOutlined as CheckIcon,
  CancelOutlined as CancelIcon
} from "@material-ui/icons";


export default forwardRef((props, ref) => {
  const classes = useStyles();
  const [data, setData] = useState({
    quantity: 0,
    price: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  
  // useEffect(() => {
  //   if (props._id) {
  //     getDetailWithdraw()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props._id]);

  useImperativeHandle(ref, () => ({

    async approvePayment(status) {
      setIsLoading(true);
      // const res = await PaymentService.update({
      //   _id: payment._id,
      //   status,
      //   note: payment.note
      // }).catch(() => { });

      // if (res) {
      //   sendNotification({
      //     type: "info",
      //     message: `${status === 'APPROVED' ? 'Approve' : 'Reject'} success`,
      //     color: "success"
      //   });
      //   getDetailWithdraw();
      //   setIsResetListPayment(true)
      // }
      // props.handleCloseConfirmModal();
      setIsLoading(false);
    }

  }));

  async function addPhoneSetting() {
    setIsLoading(true);
    const res = await PhoneSettingService.addPhoneSetting({  
      quantity: data.quantity,
      price: data.price 
    }).catch();
    if (res) {
      setData(res);
      handleClose(true);
    }
    setIsLoading(false)
  }

  const handleChangeInput = event => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: !isNaN(value) && value > 0 ? value : 0
    })
  }

  const handleClose = (isResetList = false) => {
    props.handleCloseModal(isResetList);
    setData({});
  }

  return (
    <>
      <div >
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={props.isShow}
          onClose={handleClose}
        >
          <div
            style={{
              outline: 'none',
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            className={classes.paper}>
            <div className={classes.header}><span>Add Price</span></div>
            <div className={classes.content}>
              <div className={classes.requestInfo}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.inputMethod}
                      id="quantity"
                      name="quantity"
                      label={`Quantity`}
                      type="number"
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeInput}
                      value={data.quantity || ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="price"
                      name="price"
                      label={`Price`}
                      type="number"
                      helperText=""
                      fullWidth
                      // multiline={true}
                      // rows={5}
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeInput}
                      value={data.price || ''}
                    />
                  </Grid>
                  <Grid
                    item xs={8}
                    style={{ width: '100%' }}
                  >
                    <Button
                      className={classes.buttonApprove}
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                      onClick={() => { addPhoneSetting() }}
                      disabled={isLoading}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

