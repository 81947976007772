import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControlLabel,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Modal,
  Button,
  InputLabel,
  Select,
  MenuItem,
  // Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';

// components
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PageTitle from "../../components/PageTitle/PageTitle";
import Divider from "../../components/Divider";
import Widget from "../../components/Widget/Widget";
import { sendNotification } from '../../components/Notification/Notification'
// import Dropzone from "../../components/Dropzone";
import Candidate from "./components/Candidate";

// service
import * as BookingService from '../../services/booking.service';
import * as CountryService from '../../services/country.service';
import * as AreaOfConcernService from '../../services/areaOfConcern.service';
import * as RankService from '../../services/rank.service';

// icon
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

// variables
import {
  IMAGE_DEFAULT,
  AVATAR_DEFAULT,
  HOST_IMAGE,
} from '../../constant/constant';

export default function DetailUser(props) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitiating, setIsInitiating] = useState(true);

  const [booking, setBooking] = useState(null);

  const [isOpenLightbox, setIsOpenLigtbox] = useState(false);
  const [imgLightbox, setImgLightbox] = useState('');

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  const [province, setProvince] = useState({});
  const [district, setDistrict] = useState({});
  const [ward, setWard] = useState({});

  const [inputProvince, setInputProvince] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');
  const [inputWard, setInputWard] = useState('');

  const [listRank, setListRank] = useState([]);
  const [listTempAOC, setListTempAOC] = useState([]);


  const [isPriceExact, setIsPriceExact] = useState(true);

  const [validate, setValidate] = useState({
    title: true,
    price: true,
    priceStart: true,
    priceEnd: true
  });

  useEffect(() => {
    const bookingId = props.match.params.id;
    if (bookingId) { initPage({ bookingId }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getListDistrict({ provinceId: province.provinceId })
  }, [province])

  useEffect(() => {
    getListWard({ districtId: district.districtId })
  }, [district])

  useEffect(() => {

    if (!booking) return;
    let newValidate = { ...validate };

    newValidate = {
      ...newValidate,
      title: booking.title ? true : false
    }

    if (isPriceExact) {
      newValidate = booking.price && +booking.price > 0 ?
        {
          ...newValidate,
          price: true,
          priceEnd: true,
          priceStart: true
        } :
        {
          ...newValidate,
          price: false,
          priceEnd: true,
          priceStart: true
        }
    }

    if (!isPriceExact) {
      newValidate = booking.priceStart && +booking.priceStart > 0 ?
        {
          ...newValidate,
          price: true,
          priceStart: true
        } :
        {
          ...newValidate,
          price: true,
          priceStart: false
        }

      newValidate = booking.priceEnd && +booking.priceEnd > 0 ?
        {
          ...newValidate,
          price: true,
          priceEnd: true
        } :
        {
          ...newValidate,
          price: true,
          priceEnd: false
        }

      newValidate = {
        ...newValidate,
        ...+booking.priceStart > +booking.priceEnd ? {
          priceStart: false,
          priceEnd: false
        } : {}
      }
    }

    setValidate(newValidate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPriceExact, booking])

  async function initPage(params) {
    setIsInitiating(true);
    const res = await getDetailBooking(params);
    if (res) {
      await getListAreasOfConcern(res);
      await getListProvince(res);
      await getListRank({});
    }
    setIsInitiating(false)
  }

  async function getDetailBooking(params) {
    const res = await BookingService.detailBooking(params).catch();
    if (res) {
      setBooking(res);
      if (res.price) setIsPriceExact(true)
      if (res.priceEnd) setIsPriceExact(false)
    }
    return res;
  };

  async function getListRank(params) {
    const res = await RankService.list(params).catch();
    if (res) {
      setListRank(res);
    }
    return res;
  };

  async function getListAreasOfConcern(params) {
    setIsLoading(true);
    let res = await AreaOfConcernService.list(params).catch();
    if (res) {
      setListTempAOC(res);
    }
    setIsLoading(false)
    return params;
  };

  async function getListProvince(booking) {
    const listProv = await CountryService.listProvince({}).catch();
    if (!listProv) return;
    setListProvince(listProv);

    // Province
    if (!booking.province) return;
    const provinceInArr = listProv.find(e => e.name === booking.province);
    setProvince(provinceInArr);

    // District
    if (!booking.district) return;
    const listDis = await CountryService.listDistrict({ provinceId: provinceInArr.provinceId }).catch();
    if (!listDis) return;

    const districtInArr = listDis.find(e => e.name === booking.district);
    setDistrict(districtInArr);

    // Ward
    if (!booking.ward) return;
    const listWa = await CountryService.listWard({ districtId: districtInArr.districtId }).catch();
    if (!listWa) return;

    const wardInArr = listWa.find(e => e.name === booking.ward);
    setWard(wardInArr);
  };

  async function getListDistrict(params) {
    setIsLoading(true);
    const res = await CountryService.listDistrict(params).catch();
    if (res) {
      setListDistrict(res);
    }
    setIsLoading(false)
  };

  async function getListWard(params) {
    setIsLoading(true);
    const res = await CountryService.listWard(params).catch();
    if (res) {
      setListWard(res);
    }
    setIsLoading(false)
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    setBooking({
      ...booking,
      [name]: value
    })
  }

  const handleChangeRadioPrice = event => {
    setIsPriceExact(event.target.value === "Exactly");
  }

  const handleChangePaymentMethod = event => {
    setBooking({
      ...booking,
      paymentMethod: event.target.value
    })
  }

  const handleChangeProvince = (event, newValue) => {
    if (newValue) {
      setProvince(newValue)

      setDistrict({})
      setListDistrict([])

      setWard({})
      setListWard([])

      setBooking({
        ...booking,
        province: newValue.name,
        district: '',
        ward: ''
      })
    }
  }

  const handleChangeDistrict = (event, newValue) => {
    if (newValue) {
      setDistrict(newValue)
      setWard({})
      setListWard([])
      setBooking({
        ...booking,
        district: newValue.name,
        ward: ''
      })
    }
  }

  const handleChangeWard = (event, newValue) => {
    if (newValue) {
      setWard(newValue)
      setBooking({
        ...booking,
        ward: newValue.name
      })
    }
  }

  const handleChangeStartTime = (date) => {
    if (date) {
      setBooking({
        ...booking,
        startTime: date
      })
      return;
    }
  }

  const handleChangeEndTime = (date) => {
    if (date) {
      setBooking({
        ...booking,
        endTime: date
      })
      return;
    }
  }

  // Light box handler
  const renderLightbox = () => (
    <div className={classes.paper}>
      <img
        className={classes.imgBorder}
        title="Lightbox"
        alt="Lightbox"
        src={
          imgLightbox
            ? `${HOST_IMAGE}${imgLightbox}`
            : IMAGE_DEFAULT
        }
      />
    </div>
  );

  const handleOpenLightbox = (src) => {
    setImgLightbox(src)
    setIsOpenLigtbox(true);
  };

  const handleCloseLightbox = () => {
    setIsOpenLigtbox(false);
  };

  // End Light box handler

  const handleSave = () => {
    let newBooking;
    if (isPriceExact)
      newBooking = {
        ...booking,
        priceStart: '',
        priceEnd: ''
      }
    else
      newBooking = {
        ...booking,
        price: ''
      }

    saveData(newBooking);
    setBooking(newBooking);
  }

  const saveData = (data) => {
    setIsLoading(true);
    BookingService.updateBooking(data)
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Update success",
            color: "success"
          })
        }
      })
      .catch(() => setIsLoading(false));
  }

  const handleConfirmApproveBooking = async () => {
    setIsLoading(true);

    BookingService.approveBooking({ workID: booking._id })
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Approved booking successfully",
            color: "success"
          })
          return getDetailBooking({ bookingId: booking._id })
        }
      })
      .catch(() => setIsLoading(false));
    setIsOpenDialog(false);
    setIsLoading(false)
  }

  const handleConfirmCancelBooking = async () => {
    setIsLoading(true);

    BookingService.cancelBooking({ workID: booking._id })
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Canceled booking successfully",
            color: "success"
          })
          return getDetailBooking({ bookingId: booking._id })
        }
      })
      .catch(() => setIsLoading(false));
    setIsOpenDialog(false);
    setIsLoading(false);
  }

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  }

  const handleApproveBooking = () => {
    setIsOpenDialog(true);
    setIsApprove(true)
  }

  const handleCancelBooking = () => {
    setIsOpenDialog(true);
    setIsApprove(false)
  }

  return booking && !isInitiating && (
    <>
      <Modal
        open={isOpenLightbox}
        onClose={handleCloseLightbox}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {renderLightbox()}
      </Modal>
      <Dialog
        className={classes.customDialog}
        open={isOpenDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{
          isApprove ? "Approve this booking?" : "Cancel this booking?"
        }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button
            onClick={isApprove ? handleConfirmApproveBooking : handleConfirmCancelBooking}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PageTitle title="Booking Detail" />
      <div className={classes.formDetail}>
        <Widget
          disableWidgetMenu
        // style={{width: '1100px'}}
        >
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider style={{ width: '560px' }}>Booking information</Divider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  helperText={!validate.title && "Invalid Title"}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  value={booking.title || ''}
                  onChange={handleChangeInput}
                  error={!validate.title}
                />
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: '7px' }}>
                    <FormLabel component="legend">Price</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      onChange={handleChangeRadioPrice}
                    >
                      <FormControlLabel
                        checked={isPriceExact}
                        value="Exactly"
                        control={<Radio />}
                        label="Exactly"
                      />
                      <FormControlLabel
                        checked={!isPriceExact}
                        value="Estimate"
                        control={<Radio />}
                        label="Estimate"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {isPriceExact ? (
                  <Grid item xs={8}>
                    <TextField
                      id="price"
                      name="price"
                      label="Price"
                      helperText={!validate.price && "Invalid Price"}
                      fullWidth
                      margin="normal"
                      type="number"
                      variant="outlined"
                      size="medium"
                      value={booking.price || ''}
                      onChange={handleChangeInput}
                      required
                      error={!validate.price}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        id="priceStart"
                        name="priceStart"
                        label="Price Start"
                        helperText={!validate.priceStart && "Invalid Price Start"}
                        fullWidth
                        margin="normal"
                        type="number"
                        variant="outlined"
                        size="medium"
                        value={booking.priceStart || ''}
                        onChange={handleChangeInput}
                        required
                        error={!validate.priceStart}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="priceEnd"
                        name="priceEnd"
                        label="Price End"
                        helperText={!validate.priceEnd && "Invalid Price End"}
                        fullWidth
                        margin="normal"
                        type="number"
                        variant="outlined"
                        size="medium"
                        value={booking.priceEnd || ''}
                        onChange={handleChangeInput}
                        required
                        error={!validate.priceEnd}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: '7px' }}>
                  <FormLabel component="legend">Payment Method</FormLabel>
                  <RadioGroup
                    row
                    aria-label="paymentMethod"
                    name="paymentMethod"
                    onChange={handleChangePaymentMethod}
                  >
                    <FormControlLabel
                      checked={booking.paymentMethod === "BOOKING_WALLET"}
                      value="BOOKING_WALLET"
                      control={<Radio />}
                      label="Wallet"
                    />
                    <FormControlLabel
                      checked={booking.paymentMethod === "CASH"}
                      value="CASH"
                      control={<Radio />}
                      label="Cash"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '16px' }}
                >
                  <InputLabel>KOL Rank</InputLabel>
                  <Select
                    id="rankKOLID"
                    name="rankKOLID"
                    fullWidth
                    value={booking?.rankKOLID}
                    onChange={handleChangeInput}
                    label="Rank"
                  >
                    {listRank.length && listRank.map(e => (
                      <MenuItem value={e._id}>{e.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: '16px' }}
                >
                  <InputLabel>Area Of Concern</InputLabel>
                  <Select
                    id="areasOfConcernID"
                    name="areasOfConcernID"
                    fullWidth
                    value={booking?.areasOfConcernID}
                    onChange={handleChangeInput}
                    label="Rank"
                  >
                    {listTempAOC.length && listTempAOC.map(e => (
                      <MenuItem value={e._id}>{e.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  // style={{color: '#28a745 !important'}}
                  id="status"
                  name="status"
                  label="Status"
                  helperText=""
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  value={booking.status || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    fullWidth
                    margin="normal"
                    id="startTime"
                    name="startTime"
                    label="Start Time"
                    value={booking?.startTime}
                    onChange={handleChangeStartTime}
                    format="DD/MM/YYYY - HH:mm"
                    inputVariant="outlined"
                  // disablePast
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    fullWidth
                    margin="normal"
                    id="endTime"
                    name="endTime"
                    label="End Time"
                    value={booking?.endTime}
                    onChange={handleChangeEndTime}
                    format="DD/MM/YYYY - HH:mm"
                    inputVariant="outlined"
                  // disablePast
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={province || ''}
                  onChange={handleChangeProvince}
                  inputValue={inputProvince}
                  onInputChange={(event, newInputValue) => {
                    setInputProvince(newInputValue);
                  }}
                  id="province"
                  options={listProvince}
                  getOptionLabel={option => option.name || booking.province || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Province" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={district || ''}
                  onChange={handleChangeDistrict}
                  inputValue={inputDistrict}
                  onInputChange={(event, newInputValue) => {
                    setInputDistrict(newInputValue);
                  }}
                  id="district"
                  options={listDistrict}
                  getOptionLabel={option => option.name || booking.district || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="District" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={ward || ''}
                  onChange={handleChangeWard}
                  inputValue={inputWard}
                  onInputChange={(event, newInputValue) => {
                    setInputWard(newInputValue);
                  }}
                  id="ward"
                  options={listWard}
                  getOptionLabel={option => option.name || booking.ward || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Ward" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={booking.address || ''}
                  onChange={handleChangeInput}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="describe"
                  name="describe"
                  label="Description"
                  helperText=""
                  fullWidth
                  multiline={true}
                  rows={9}
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  value={booking.describe || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="otherRequest"
                  name="otherRequest"
                  label="Other Request"
                  helperText=""
                  fullWidth
                  multiline={true}
                  rows={9}
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  value={booking.otherRequest || ''}
                  onChange={handleChangeInput}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ width: '600px' }}>Employer information</Divider>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.avatarCover}>
                  <Avatar
                    alt="Avatar"
                    src={
                      booking.creator?.profile?.avatar
                        ? `${HOST_IMAGE}${booking.creator?.profile?.avatar}`
                        : AVATAR_DEFAULT
                    }
                    className={classes.avatar}
                    onClick={() => { handleOpenLightbox(booking.creator?.profile?.avatar) }}
                  />
                  {booking.creator?._id && (
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/employer/${booking.creator?._id || ''}`}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '10px' }}
                      >
                        View Detail
                      </Button>
                    </Link>
                  )}
                </div>
              </Grid>
              <Grid item container xs={8} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="fullName"
                    name="fullName"
                    label="Fullname"
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.creator?.profile?.fullName || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="cardId"
                    name="cardId"
                    label="Identification Card"
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.creator?.profile?.cardID || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="phoneCode"
                    name="phoneCode"
                    label="Phone Code"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.creator?.codePhone || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.creator?.phone || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ width: '440px' }}>KOL information</Divider>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.avatarCover}>
                  <Avatar
                    alt="Avatar"
                    src={
                      booking.kolID?.profile?.avatar
                        ? `${HOST_IMAGE}${booking.kol?.kolID?.profile?.avatar}`
                        : AVATAR_DEFAULT
                    }
                    className={classes.avatar}
                    onClick={() => { handleOpenLightbox(booking.kolID?.profile?.avatar) }}
                  />
                  {booking.kolID?._id && (
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/kol/${booking.kolID?._id || ''}`}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '10px' }}
                      >
                        View Detail
                      </Button>
                    </Link>
                  )}
                </div>
              </Grid>
              <Grid item container xs={8} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="fullName"
                    name="fullName"
                    label="Fullname"
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.kolID?.profile?.fullName || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="rank"
                    name="rank"
                    label="Rank KOL"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.kol?.rankTypeKOL || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="price"
                    name="price"
                    label="Price"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.kol?.priceKOL || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="phoneCode"
                    name="phoneCode"
                    label="Phone Code"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.kolID?.codePhone || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={booking.kolID?.phone || ''}
                    style={{ marginTop: '24px' }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.listCandidates}>
                  {booking.candidates === null || booking.candidates?.length === 0 ? (
                    <span style={{ padding: '20px', fontWeight: 600 }}>No Data</span>
                  ) : (
                    <Candidate
                      kolID={booking.kol?.kolID?._id}
                      candidates={booking.candidates || []}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Widget>
      </div>
      <div className={`${classes.divBox}`}>
        {booking?.status === 'PENDING' && (
          <>
            <Button
              className={classes.buttonApprove}
              variant="contained"
              color="primary"
              onClick={handleApproveBooking}
              startIcon={<CheckIcon />}
              disabled={isLoading || !Object.values(validate).every(e => e)}
            >
              Approve
            </Button>
            <Button
              className={classes.buttonCancel}
              variant="contained"
              color="secondary"
              onClick={handleCancelBooking}
              startIcon={<CancelIcon />}
              disabled={isLoading || !Object.values(validate).every(e => e)}
            >
              Cancel
            </Button>
          </>
        )}
        <Button
          className={classes.buttonSave}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={isLoading || !Object.values(validate).every(e => e)}
        >
          Save
        </Button>
      </div>
    </>
  );
}
