import AxiosBase from './axiosClient';

export const listSetting = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/setting/list`);
    return res.data;
}

export const updateSetting = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/setting`, params);
    return res.data;
}