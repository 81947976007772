import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  FormControlLabel,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Modal,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';
import MomentUtils from '@date-io/moment';
import { Editor } from '@tinymce/tinymce-react';

// components
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PageTitle from "../../components/PageTitle/PageTitle";
import Divider from "../../components/Divider";
import Dropzone from "../../components/Dropzone";
import Widget from "../../components/Widget/Widget";
import { sendNotification } from '../../components/Notification/Notification'

// service
import * as PostService from '../../services/post.service';

// context
import { useLayoutDispatch, setHeader } from "../../context/LayoutContext";

// icon
import {
  Save as SaveIcon,
  LocalAtm as LocalAtmIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckIcon,
  Link as LinkIcon
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

// resources
import dataCountries from "../../resources/countries.json";

// utils
import { formatMoney } from "../../utils";

// variables
import {
  IMAGE_DEFAULT,
  AVATAR_DEFAULT,
  TICK_VERIFIED,
  HOST_IMAGE,
  LITERACY,
  RELATIONSHIP
} from '../../constant/constant';

export default function DetailUser(props) {
  const classes = useStyles();
  const editorRef = useRef(null);
  const layoutDispatch = useLayoutDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitiating, setIsInitiating] = useState(true);
  const [images, setImages] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [post, setPost] = useState({});

  useEffect(() => {
    const postId = props.match.params.id;
    if (postId) { initPage({ postId }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function initPage(params) {
    setIsInitiating(true);
    const res = await getDetailPost(params);
    setIsInitiating(false)
  }

  async function getDetailPost(params) {
    const res = await PostService.detail({
      _id: params.postId
    }).catch();

    if (res) {
      setPost(res);
      setImages(res.images);
    }
    return res;
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    setPost({
      ...post,
      [name]: value
    })
  }

  const handleApprovePost = (postId) => {
    setIsShow(true);
    setIsApprove(true)
  }

  const handleRejectPost = (postId) => {
    setIsShow(true);
    setIsApprove(false)
  }

  const handleClose = () => {
    setIsShow(false);
  }

  const handleConfirmApprovePost = async () => {
    setIsLoading(true);

    if (!post._id) {
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await PostService.approve({ _id: post._id, status: "APPROVED" }).catch();
    if (res) {
      sendNotification({
        type: "info",
        message: "Approved post successfully",
        color: "success"
      })
      await getDetailPost({postId: post._id});
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleConfirmRejectPost = async () => {
    setIsLoading(true);

    if (!post._id) {
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await PostService.approve({ _id: post._id, status: "REJECTED" }).catch();
    if (res) {
      sendNotification({
        type: "info",
        message: "Rejected booking successfully",
        color: "success"
      })
      await getDetailPost({ postId: post._id });
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true);

    const res = await PostService.update({ _id: post._id, content: post.content }).catch();
    if (res) {
      sendNotification({
        type: "info",
        message: "Rejected booking successfully",
        color: "success"
      })
      await getDetailPost({ postId: post._id });
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleEditorChange = (value, editor) => {
    setPost({ 
      ...post,
      content: value 
    });
  }

  return post && !isInitiating && (
    <>
    <Dialog
        className={classes.customDialog}
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{
          isApprove ? "Approve this post?" : "Reject this post?"
        }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={isApprove ? handleConfirmApprovePost : handleConfirmRejectPost}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PageTitle title="Post Detail" />
      <div className={classes.formDetail}>
        <Widget
          disableWidgetMenu
        // style={{width: '1100px'}}
        >
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider style={{ width: '450px' }}>Post information</Divider>
              </Grid>
              <Grid item xs={12}>
                <Editor
                  apiKey={process.env.REACT_APP_TINY_KEY}
                  onInit={(evt, editor) => editorRef.current = editor}
                  // initialValue={post.content}
                  value={post.content}
                  onEditorChange={handleEditorChange}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen ',
                      'insertdatetime media table paste code help wordcount',
                      'image'
                    ],
                    // menubar: 'insert',
                    toolbar: 'undo redo | formatselect | ' +
                      'image bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat fullscreen | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    selector: 'textarea',
                    image_list: [
                      // {title: 'My image 1', value: 'https://i.pinimg.com/564x/92/26/5c/92265c40c8e428122e0b32adc1994594.jpg'},
                      // {title: 'My image 2', value: 'https://vaithuhayho.com/wp-content/uploads/2021/03/hinh-anh-dep-ve-tinh-yeu-9-1.jpg'}
                    ],
                    fullscreen_native: true,
                    paste_data_images: true,
                    setup: function(editor) {
                      editor.on('FullscreenStateChanged', function(e) {
                        setHeader(layoutDispatch, !e.state)
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Widget>
      </div>
      <div className={classes.divBox}>
        
        {(post?.status === 'PENDING') && (
          <>
            <Button
              className={classes.buttonApprove}
              variant="contained"
              color="primary"
              onClick={handleApprovePost}
              startIcon={<CheckIcon />}
              disabled={isLoading}
            >
              Approve
            </Button>
            <Button
              className={classes.buttonReject}
              variant="contained"
              color="secondary"
              onClick={handleRejectPost}
              startIcon={<CancelIcon />}
              disabled={isLoading}
            >
              Reject
            </Button>
          </>
        )}
        <Button
          className={classes.buttonSave}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
        </Button>
      </div>
    </>
  );
}
