import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  Avatar,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Chip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { sendNotification } from '../../../../components/Notification/Notification';

//css
import { useStyles } from './style';

// utils
import { formatMoney } from "../../../../utils";

// variables
import {
  AVATAR_DEFAULT,
  HOST_IMAGE
} from '../../../../constant/constant';

// service
import * as PaymentService from '../../../../services/payment.service';

// icon
import {
  VisibilityOutlined as EyeIcon,
  CheckCircleOutlined as CheckIcon,
  CancelOutlined as CancelIcon
} from "@material-ui/icons";


export default forwardRef((props, ref) => {
  const classes = useStyles();
  const [payment, setPayment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isResetListPayment, setIsResetListPayment] = useState(false);

  useEffect(() => {
    if (props._id) {
      getDetailWithdraw()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._id]);

  useImperativeHandle(ref, () => ({

    async approvePayment(status) {
      setIsLoading(true);
      const res = await PaymentService.update({
        _id: payment._id,
        status,
        note: payment.note
      }).catch(()=>{});

      if (res) {
        sendNotification({
          type: "info",
          message: `${status === 'APPROVED' ? 'Approve' : 'Reject'} success`,
          color: "success"
        });
        getDetailWithdraw();
        setIsResetListPayment(true)
      }
      props.handleCloseConfirmModal();
      setIsLoading(false);
    }

  }));

  async function getDetailWithdraw() {
    setIsLoading(true);
    const res = await PaymentService.detail({ _id: props._id }).catch();
    if (res) {
      setPayment(res);
      // props.setRefundAmount(res.refundAmount || 0);
      // props.setCurrency(res.currency || 'VND');
    }
    setIsLoading(false)
  }

  const handleChangeInput = event => {
    const { name, value } = event.target;
    setPayment({
      ...payment,
      [name]: value
    })
  }

  const renderAddress = () => {
    let str = '';
    if (!payment?.userID) return str;

    if (payment.userID.profile?.province) str += payment.userID.profile?.province;
    if (payment.userID.profile?.country) str += 
    (payment.userID.profile?.province ? ', ' : '') + payment.userID.profile?.country;

    return str;
  }

  const handleClose = () => {
    props.handleCloseDetailModal(isResetListPayment);
    setPayment({});
    setIsResetListPayment(false);
  }

  return (
    <>
      <div >
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={props.isShow}
          onClose={handleClose}
        >
          <div
            style={{
              outline: 'none',
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            className={classes.paper}>
            <div className={classes.header}><span>Detail Payment</span></div>
            <div className={classes.content}>
              <div className={classes.userInfo}>
                {/* <div className={classes.divider}><span>User Information</span></div> */}
                <div className={classes.userInfoCover}>
                  <div className={classes.employerInfo}>
                    <h3>{payment.userID?.type}</h3>
                    <div className={classes.avatarCover}>
                      <Avatar
                        alt="Avatar"
                        src={
                          payment.userID?.profile?.avatar
                            ? `${HOST_IMAGE}${payment.userID?.profile?.avatar}`
                            : AVATAR_DEFAULT}
                        className={classes.avatar}
                      // onClick={() => { handleOpenLightbox(avatar) }}
                      />
                    </div>
                    <h3
                      style={{
                        marginBottom: '0px'
                      }}
                    >{payment.userID?.profile?.fullName || ''}</h3>
                    <h4 style={{ margin: '0 0' }}>{payment.userID?.phone}</h4>
                    <h4 style={{ margin: '0 0' }}>
                      {
                        renderAddress()
                      }
                    </h4>
                    {payment.userID && (
                      <Link
                        style={{ textDecoration: 'none', marginTop: '15px' }}
                        to={`/${payment.userID?.type?.toUpperCase()}/${payment.userID?._id || ''}`}
                        target="_blank"
                        rel="noopener noreferrer" >
                        <Button
                          className={classes.buttonSave}
                          variant="contained"
                          color="primary"
                          startIcon={<EyeIcon />}
                        >
                          View Detail
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div className={classes.requestInfo}>
                <div className={classes.divider}>
                  <span>Withdraw Information</span>
                </div>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.inputMethod}
                      id="type"
                      name="type"
                      label={`Method`}
                      helperText=""
                      fullWidth
                      // multiline={true}
                      // rows={5}
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={payment.type || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="phone"
                      name="phone"
                      label={`Phone`}
                      helperText=""
                      fullWidth
                      // multiline={true}
                      // rows={5}
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={payment.phone || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="amount"
                      name="amount"
                      label={`Amount`}
                      helperText=""
                      fullWidth
                      // multiline={true}
                      // rows={5}
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={payment.amount ? formatMoney(payment.amount) : '0'}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="fee"
                      name="fee"
                      label={`Fee`}
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={payment.fee || '0'}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="note"
                      name="note"
                      label={`Note`}
                      helperText=""
                      fullWidth
                      multiline={true}
                      rows={5}
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={payment.note || ''}
                      onChange={handleChangeInput}
                    />
                  </Grid>
                  {payment?.status === 'PENDING' && (
                    <Grid
                      item xs={12}
                      style={{ width: '100%' }}
                    >
                      <Button
                        className={classes.buttonReject}
                        variant="contained"
                        color="primary"
                        startIcon={<CancelIcon />}
                        onClick={() => { props.handleShowConfirmModal(false) }}
                        disabled={isLoading || !payment}
                      >
                        Reject
                      </Button>
                      <Button
                        className={classes.buttonApprove}
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        onClick={() => { props.handleShowConfirmModal(true) }}
                        disabled={isLoading || !payment}
                      >
                        Approve
                      </Button>
                    </Grid>
                  )}

                  {(payment.status === 'APPROVED' || payment.status === 'REJECTED') && (
                    <div className={classes.divSolution}>
                      <Chip
                        style={{
                          padding: '20px 15px',
                          fontSize: '20px',
                          borderWidth: 'medium',
                          minWidth: '100px',
                          justifyContent: 'space-between',
                          fontWeight: 600,
                          ...payment.status === 'APPROVED' ? {
                            borderColor: '#3CD4A0',
                            color: '#3CD4A0'
                          } : {
                            borderColor: '#dc3545',
                            color: '#dc3545'
                          }
                        }}
                        size="medium"
                        icon={
                          payment.status === 'APPROVED' ?
                          <CheckIcon style={{
                            fontSize: '34px',
                            marginRight: '10px',
                            fontWeight: 600,
                            borderColor: '#3CD4A0',
                            color: '#3CD4A0'
                          }} /> :
                          <CancelIcon style={{
                            fontSize: '34px',
                            marginRight: '10px',
                            fontWeight: 600,
                            borderColor: '#dc3545',
                            color: '#dc3545'
                          }} />
                        }
                        label={payment.status}
                        color="primary"
                        variant="outlined"
                      />
                    </div>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

