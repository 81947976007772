import React from "react";
import * as adminService from '../services/admin.service.js';

var AdminStateContext = React.createContext();
var AdminDispatchContext = React.createContext();
const initState = {
  id: '',
  email: '',
  phone: '',
  fullName: '',
  isAuthenticated: false
};

function adminReducer(state = initState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return { ...state, ...action.payload, isAuthenticated: true };
    case "LOGIN_SUCCESS":
      return { ...state, ...action.payload, isAuthenticated: true };
    case "LOG_OUT_SUCCESS":
      return initState;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminProvider({ children }) {
  var [state, dispatch] = React.useReducer(adminReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <AdminStateContext.Provider value={state}>
      <AdminDispatchContext.Provider value={dispatch}>
        {children}
      </AdminDispatchContext.Provider>
    </AdminStateContext.Provider>
  );
}

function useAdminState() {
  var context = React.useContext(AdminStateContext);
  if (context === undefined) {
    throw new Error("useAdminState must be used within a AdminProvider");
  }
  return context;
}

function useAdminDispatch() {
  var context = React.useContext(AdminDispatchContext);
  if (context === undefined) {
    throw new Error("useAdminDispatch must be used within a AdminProvider");
  }
  return context;
}

export { AdminProvider, useAdminState, useAdminDispatch, login, logOut };

// ###########################################################

async function login(dispatch, email, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    const res = await adminService.login({ email, password })
      .catch(() => setIsLoading(false));

    if (res) {
      const data = {
        id: res.user?._id,
        email: res.user?.email,
        phone: res.user?.profile?.phone,
        fullName: res.profile?.user?.fullName,
      };

      localStorage.setItem('kol_token', res.token)
      localStorage.setItem('lang', 'en')

      setError(null)
      setIsLoading(false)
      
      dispatch({ type: 'LOGIN_SUCCESS', payload: data })
      history.push('/dashboard')
    }
    
    setIsLoading(false)
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function logOut(dispatch, history) {
  localStorage.removeItem("kol_token");
  dispatch({ type: "LOG_OUT_SUCCESS" });
  history.push("/login");
}
