import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  overridedClass: {
    '& .MuiPaper-root': {
      borderTopLeftRadius: '0px !important',
      borderTopRightRadius: '0px !important',
    }
  },
  tableOverflow: {
    overflow: 'auto',
    // borderTopLeftRadius: '0px',
    // borderTopRightRadius: '0px',
    // '& .MuiGrid-root': {
    //   borderTopLeftRadius: '0px !important',
    //   borderTopRightRadius: '0px !important',
    //   '& .MuiPaper-root': {
    //     borderTopLeftRadius: '0px !important',
    //     borderTopRightRadius: '0px !important'
    //   }
    // },
  },
  tableBody: {
    // height: '500px'
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: 'black',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  userAction: {
    display: 'flex',
    alignItems: 'center'
  },
  eyeIcon: {
    fontSize: '27px'
  },
  inputSearch: {
    width: '300px'
  },
  coverTableHead: {
    paddingTop: '18px',
    margin: '4px 40px 16px 24px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  listTabStatus: {
    backgroundColor: '#4c000000',

    '& .MuiTab-root': {
      height: '10px',
      backgroundColor: '#FFFFFF',
      marginRight: '10px',
      borderBottom: '0.5px solid #f5f7ff',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px'
    }
  },
  tab: {
    paddingRight: '50px',
    paddingLeft: '50px',
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(1) translate(100%, -30%)'
    }
  },
  tabActive: {
    // boxShadow: '0px 0px 5px gray',
    color: 'red !important',
    borderBottom: '0px !important',
    fontWeight: '600',
    textDecoration: 'underline'
  },
  link: {
    display: 'flex',
    height: '32px',
    color: 'gray',
    '&:hover': {
      color: 'blue'
    }
  },
  customDialog: {
    '& .MuiDialog-paper': {
      width: '480px',
      boxShadow: '0px 0px 15px gray'
    }
  }
}))

