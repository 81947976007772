import React, { useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import * as UploadService from '../../services/upload.service';

export default function Dropzone(props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSubmit = async (files) => {
        if (files.length > 1) {
            let images = [];
            for (let file of files) {
                const res = await UploadService.uploadFile({ file })
                    .catch(console.log);

                images.push(res);
            }

            props.handleUploadFile(images)
            setIsOpen(false);
        } else {
            const res = await UploadService.uploadFile({ file: files[0] })
                .catch(console.log);

            if (res) {
                props.handleUploadFile([res])
                setIsOpen(false);
            }
        }
    }

    const handleOpen = () => {
        setIsOpen(true)
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                style={{ marginTop: '20px' }}
            >
                {props.buttonLabel || 'Upload Image'}
            </Button>
            <DropzoneDialog
                open={isOpen}
                onSave={handleSubmit}
                acceptedFiles={props.acceptedFiles || ['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
                filesLimit={props.limit || 100}
                showFileNamesInPreview={false}
                showFileNames={false}
                showAlerts={['error', 'info']}
            />
        </div>
    );
}