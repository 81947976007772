import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        height: '950px',
        width: 650,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '5px',
        overflow: 'hidden',
    },
    header: {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        textAlign: 'center',
        padding: '18px 32px',
        backgroundColor: 'rgb(255,255,255)',
        backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(227,227,227,1) 100%)',
        '& > span': {
            fontSize: '24px',
            // textDecoration: 'underline',
            // borderBottom: '2px solid white',
            color: 'gray',
            fontWeight: 600
        }
    },
    content: {
        height: '100%',
        overflowY: 'scroll',
        // minHeight: '500px',
        padding: theme.spacing(6, 4, 3),
        paddingBottom: '100px'
    },
    bookingInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '64px'
    },
    requestInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '64px'
    },
    divider: {
        position: 'relative',
        width: '100%',
        borderBottom: '2px solid gray',
        '& span': {
            paddingRight: '8px',
            position: 'absolute',
            left: 0,
            transform: 'translateY(-50%)',
            backgroundColor: 'white',
            fontSize: '18px',
            fontWeight: 600
        }
    },
    userInfoCover: {
        marginTop: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center'
    },
    employerInfo: {
        position: 'relative',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    kolInfo: {
        position: 'relative',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    horizon: {
        minHeight: '100px',
        borderLeft: '2px solid lightgray'
    },
    avatarCover: {
        position: 'relative',
        width: theme.spacing(15),
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > img': {
            position: 'absolute',
            width: '40px',
            right: 20,
            zIndex: 1
        },
        border: '5px solid #3588ff',
        borderRadius: '50%'
    },
    avatar: {
        width: '100%',
        height: theme.spacing(14),
    },
    cancelSign: {
        position: 'absolute',
        top: '30px',
        left: '-6px',
        width: '140px',
        zIndex: 1,
        '& img': {
            width: '100%'
        }
    },
    buttonRefund: {
        marginTop: '12px',
        height: '45px',
        width: '48%',
        float: 'left',
        backgroundColor: '#ffa71a',
        '&:hover': {
            backgroundColor: '#e68e00'
        }
    },
    buttonRecruit: {
        marginTop: '12px',
        height: '45px',
        width: '48%',
        float: 'right',
        backgroundColor: '#3CD4A0',
        '&:hover': {
            backgroundColor: '#29bc89'
        }
    },
    divSolution: {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

