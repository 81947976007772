import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  Avatar,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Chip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { sendNotification } from '../../../../components/Notification/Notification';

//css
import { useStyles } from './style';

// utils
import { formatMoney } from "../../../../utils";

// variables
import {
  AVATAR_DEFAULT,
  HOST_IMAGE,
  CANCEL_SIGN
} from '../../../../constant/constant';

// service
import * as ReqCancelService from '../../../../services/requestCancellation.service';

// icon
import {
  VisibilityOutlined as EyeIcon,
  MonetizationOn as MoneyIcon,
  Loop as LoopIcon,
  CheckCircleOutlined as CheckIcon
} from "@material-ui/icons";


export default forwardRef((props, ref) => {
  const classes = useStyles();
  const [request, setRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isResetListReq, setIsResetListReq] = useState(false);

  useEffect(() => {
    if (props._id) {
      getDetailReq()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._id]);

  useImperativeHandle(ref, () => ({

    async updateReqCancel(solution) {
      setIsLoading(true);
      const res = await ReqCancelService.update({
        _id: request._id,
        faultBy: request.faultBy,
        note: request.note,
        solution
      }).catch(()=>{});

      if (res) {
        sendNotification({
          type: "info",
          message: `${solution === 'REFUND' ? 'Refund' : 'Recruit'} success`,
          color: "success"
        });
        getDetailReq();
        setIsResetListReq(true)
      }
      // props.handleCloseConfirmModal();
      setIsLoading(false);
    }

  }));

  async function getDetailReq() {
    setIsLoading(true);
    const res = await ReqCancelService.detail({ _id: props._id }).catch();
    if (res) {
      setRequest(res);
      props.setRefundAmount(res.refundAmount || 0);
      props.setCurrency(res.currency || 'VND');
    }
    setIsLoading(false)
  }

  const handleChangeInput = event => {
    const { name, value } = event.target;
    setRequest({
      ...request,
      [name]: value
    })
  }

  const renderAddress = () => {
    let str = '';
    if (!request?.work) return str;

    if (request.work.address) str += request.work.address;
    if (request.work.ward) str += (request.work.address ? ', ' : '') + request.work.ward;
    if (request.work.district) str += (request.work.ward ? ', ' : '') + request.work.district;
    if (request.work.province) str += (request.work.district ? ', ' : '') + request.work.province;

    return str;
  }

  // const updateReqCancel = async (solution) => {
  //   setIsLoading(true);
  //   const res = await ReqCancelService.update({
  //     _id: request._id,
  //     faultBy: request.faultBy,
  //     note: request.note,
  //     // solution
  //   }).catch();

  //   if (res) {
  //     sendNotification({
  //       type: "info",
  //       message: `${solution === 'REFUND' ? 'Refund' : 'Recruit'} success`,
  //       color: "success"
  //     });
  //     getDetailReq();
  //     setIsResetListReq(true)
  //   }
  //   // props.handleCloseConfirmModal();
  //   setIsLoading(false);
  // }

  const handleClose = () => {
    props.handleCloseDetailModal(isResetListReq);
    setRequest({});
    setIsResetListReq(false);
  }

  return (
    <>
      <div >
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={props.isShow}
          onClose={handleClose}
        >
          <div
            style={{
              outline: 'none',
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            className={classes.paper}>
            <div className={classes.header}><span>Detail Request</span></div>
            <div className={classes.content}>
              <div className={classes.bookingInfo}>
                <div className={classes.divider}><span>Booking Information</span></div>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ marginTop: '35px' }}
                      id="title"
                      name="title"
                      label="Title"
                      helperText={""}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={request.work?.title || ''}
                      disabled
                    />
                  </Grid>
                  {request.work?.price != null ? (
                    <Grid item xs={12}>
                      <TextField
                        id="price"
                        name="price"
                        label="Price"
                        helperText={""}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={request.work?.price ? formatMoney(request.work?.price) : ''}
                        disabled
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          id="priceStart"
                          name="priceStart"
                          label="Price Start"
                          helperText={""}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={request.work?.priceStart ? formatMoney(request.work?.priceStart) : ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="priceEnd"
                          name="priceEnd"
                          label="Price End"
                          helperText={""}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={request.work?.priceEnd ? formatMoney(request.work?.priceEnd) : ''}
                          disabled
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      id="startTime"
                      name="startTime"
                      label="Start time"
                      helperText={""}
                      fullWidth
                      margin="normal"
                      type="text"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        request.work?.startTime ?
                          moment(request.work?.startTime).format("DD/MM/YYYY - HH:mm") : ''
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="endTime"
                      name="endTime"
                      label="End time"
                      helperText={""}
                      fullWidth
                      margin="normal"
                      type="text"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        request.work?.endTime ?
                          moment(request.work?.endTime).format("DD/MM/YYYY - HH:mm") : ''
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // style={{ marginTop: '35px' }}
                      id="address"
                      name="address"
                      label="Address"
                      helperText={""}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      value={renderAddress()}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Link
                  style={{ textDecoration: 'none', marginTop: '15px' }}
                  to={`/booking/${request.work?._id || ''}`}
                  target="_blank"
                  rel="noopener noreferrer" >
                  <Button
                    className={classes.buttonSave}
                    variant="contained"
                    color="primary"
                    startIcon={<EyeIcon />}
                  >
                    View Detail
                  </Button>
                </Link>
              </div>
              <div className={classes.userInfo}>
                <div className={classes.divider}>
                  <span>User Information</span>
                </div>
                <div className={classes.userInfoCover}>
                  {request.employer?._id !== undefined && (
                    <div className={classes.employerInfo}>
                      {request.employer?._id === request.canceler && (
                        <div className={classes.cancelSign}>
                          <img alt="cancel-sign" src={CANCEL_SIGN} />
                        </div>
                      )}
                      <h3>EMPLOYER</h3>
                      <div className={classes.avatarCover}>
                        <Avatar
                          alt="Avatar"
                          src={
                            request.employer?.profile?.avatar
                              ? `${HOST_IMAGE}${request.employer?.profile?.avatar}`
                              : AVATAR_DEFAULT}
                          className={classes.avatar}
                        // onClick={() => { handleOpenLightbox(avatar) }}
                        />
                      </div>
                      <h3
                        style={{
                          marginBottom: '0px'
                        }}
                      >{request.employer?.profile?.fullName || ''}</h3>
                      <h4 style={{ margin: '0 0' }}>{request.employer?.phone}</h4>
                      <h4 style={{ margin: '0 0' }}>
                        {
                          `${request.employer?.profile?.province ?
                            request.employer?.profile?.province + ',' : ''}
                         ${request.kemployerol?.profile?.country || 'No Country'}`
                        }
                      </h4>
                      <Link
                        style={{ textDecoration: 'none', marginTop: '15px' }}
                        to={`/employer/${request.employer?._id || ''}`}
                        target="_blank"
                        rel="noopener noreferrer" >
                        <Button
                          className={classes.buttonSave}
                          variant="contained"
                          color="primary"
                          startIcon={<EyeIcon />}
                        >
                          View Detail
                        </Button>
                      </Link>
                    </div>
                  )}
                  {request.kol?._id !== undefined && request.employer?._id !== undefined && (
                    <div className={classes.horizon}></div>
                  )}
                  {request.kol?._id !== undefined && (
                    <div className={classes.kolInfo}>
                      {request.kol?._id === request.canceler && (
                        <div className={classes.cancelSign}>
                          <img alt="cancel-sign" src={CANCEL_SIGN} />
                        </div>
                      )}
                      <h3>KOL</h3>
                      <div className={classes.avatarCover}>
                        <Avatar
                          alt="Avatar"
                          src={
                            request.kol?.profile?.avatar
                              ? `${HOST_IMAGE}${request.kol?.profile?.avatar}`
                              : AVATAR_DEFAULT}
                          className={classes.avatar}
                        // onClick={() => { handleOpenLightbox(avatar) }}
                        />
                      </div>
                      <h3
                        style={{
                          marginBottom: '0px'
                        }}
                      >{request.kol?.profile?.fullName || ''}</h3>
                      <h4 style={{ margin: '0 0' }}>{request.kol?.phone || ''}</h4>
                      <h4 style={{ margin: '0 0' }}>
                        {
                          `${request.kol?.profile?.province ?
                            request.kol?.profile?.province + ',' : ''}
                         ${request.kol?.profile?.country || 'No Country'}`
                        }
                      </h4>
                      <Link
                        style={{ textDecoration: 'none', marginTop: '15px' }}
                        to={`/kol/${request.kol?._id || ''}`}
                        target="_blank"
                        rel="noopener noreferrer" >
                        <Button
                          className={classes.buttonSave}
                          variant="contained"
                          color="primary"
                          startIcon={<EyeIcon />}
                        >
                          View Detail
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.requestInfo}>
                <div className={classes.divider}>
                  <span>Request Information</span>
                </div>

                <Grid item xs={12}>
                  <TextField
                    style={{ marginTop: '35px' }}
                    id="description"
                    name="description"
                    label={`Description Of ${request.kol?._id === request.canceler ? 'KOL' : 'EMPLOYER'}`}
                    helperText=""
                    fullWidth
                    multiline={true}
                    rows={5}
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={request.description || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    style={{ marginTop: '20px' }}
                    disabled={request?.status !== 'PENDING'}
                  >
                    <FormLabel component="legend">Fault By</FormLabel>
                    <RadioGroup
                      row
                      aria-label="faultBy"
                      name="faultBy"
                      onChange={handleChangeInput}
                    >
                      <FormControlLabel checked={request.faultBy === "EMPLOYER"} value="EMPLOYER" control={<Radio />} label="EMPLOYER" />
                      <FormControlLabel checked={request.faultBy === "KOL"} value="KOL" control={<Radio />} label="KOL" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ marginTop: '25px' }}
                    id="note"
                    name="note"
                    label="Note"
                    helperText=""
                    fullWidth
                    multiline={true}
                    rows={5}
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={request.note || ''}
                    onChange={handleChangeInput}
                    disabled={request?.status !== 'PENDING'}
                  />
                </Grid>
                {request?.status === 'PENDING' && (
                  <Grid
                    item xs={12}
                    style={{ width: '100%' }}
                  >
                    <Button
                      className={classes.buttonRefund}
                      variant="contained"
                      color="primary"
                      startIcon={<MoneyIcon />}
                      onClick={() => { props.handleShowConfirmModal(true) }}
                      disabled={isLoading || !request}
                    >
                      Refund
                    </Button>
                    <Button
                      className={classes.buttonRecruit}
                      variant="contained"
                      color="primary"
                      startIcon={<LoopIcon />}
                      onClick={() => { props.handleShowConfirmModal(false) }}
                      disabled={isLoading || !request}
                    >
                      Recruit KOL
                    </Button>
                  </Grid>
                )}
                {request.solution !== 'PENDING' && (
                  <div className={classes.divSolution}>
                    <Chip
                      style={{
                        padding: '20px 15px',
                        fontSize: '20px',
                        borderWidth: 'medium',
                        minWidth: '100px',
                        justifyContent: 'space-between',
                        fontWeight: 600,
                        ...request.solution === 'REFUND' ? {
                          borderColor: '#ffa71a',
                          color: '#ffa71a'
                        } : {
                          borderColor: '#29bc89',
                          color: '#29bc89'
                        }
                      }}
                      size="medium"
                      icon={
                        <CheckIcon style={{
                          fontSize: '34px',
                          marginRight: '10px',
                          fontWeight: 600,
                          ...request.solution === 'REFUND' ? {
                            borderColor: '#ffa71a',
                            color: '#ffa71a'
                          } : {
                            borderColor: '#29bc89',
                            color: '#29bc89'
                          }
                        }} />
                      }
                      label={request.solution}
                      color="primary"
                      variant="outlined"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

