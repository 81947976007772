import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Kol from "../../pages/kol";
import DetailKol from "../../pages/detailKol";
import Setting from "../../pages/setting";
import Booking from "../../pages/booking";
import DetailBooking from "../../pages/detailBooking";
import Employer from "../../pages/employer";
import DetailEmployer from "../../pages/detailEmployer";
import RequestCancellation from "../../pages/requestCancellation";
import Withdraw from "../../pages/withdraw";
import Transaction from "../../pages/transaction";
import Post from "../../pages/post";
import DetailPost from "../../pages/detailPost";
import Payment from "../../pages/payment";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useAdminState } from "../../context/AdminContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  const { email } = useAdminState();
  
  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              {email === "admin@xxx.xxx" && (
                <Route>
                <Route exact path="/kol" component={Kol} />
                <Route exact path="/kol/:id" component={DetailKol} />
                <Route exact path="/booking" component={Booking} />
                <Route exact path="/booking/:id" component={DetailBooking} />
                <Route exact path="/employer" component={Employer} />
                <Route exact path="/employer/:id" component={DetailEmployer} />
                <Route exact path="/post" component={Post} />
                <Route exact path="/post/:id" component={DetailPost} />
                <Route exact path="/request" component={RequestCancellation} />
                <Route exact path="/withdraw" component={Withdraw} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/transaction" component={Transaction} />
                <Route exact path="/setting" component={Setting} />
                </Route>
              )}
              <Route path="/typography" component={Typography} />
              <Route path="/tables" component={Tables} />
              <Route path="/notifications" component={Notifications} />
              <Route
                exact
                path="/ui"
                render={() => <Redirect to="/ui/icons" />}
              />
              <Route path="/ui/maps" component={Maps} />
              <Route path="/ui/icons" component={Icons} />
              <Route path="/ui/charts" component={Charts} />
            </Switch>
            {/* <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/about'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/blog'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box> */}
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
