import React, { useEffect, useState } from "react";
import {
  Chip,
  Button,
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TextField
} from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Divider from "../../components/Divider";
import Widget from "../../components/Widget/Widget";
import { sendNotification } from '../../components/Notification/Notification'
import ModalAdd from './components/modalDetail'

// service
import * as SettingService from '../../services/setting.service';
import * as PhoneSettingService from '../../services/phoneSetting.service';

// icon
import {
  RemoveRedEye as VisibilityIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  AddCircleOutlined as AddCircleOutlinedIcon
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

// utils
import { formatMoney } from "../../utils";

export default function ListSetting() {
  const classes = useStyles();
  const [listSetting, setListSetting] = useState([]);
  const [listSettingPhone, setListSettingPhone] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  // const [isResetList, setIsResetList] = useState(false);

  useEffect(() => {
    getListSetting({});
  }, [])

  // useEffect(() => {
  //   if(isResetList) {
  //     getListSetting({});
  //   }
  // }, [isResetList])

  async function getListSetting(params) {
    setIsLoading(true);
    const resListSetting = await SettingService.listSetting(params).catch(console.log);
    const resListSettingPhone = await PhoneSettingService.listPhoneSetting(params).catch(console.log);
    if (resListSetting) { setListSetting(resListSetting) }
    if (resListSettingPhone) { setListSettingPhone(resListSettingPhone) }
    setIsLoading(false);
  }

  async function getListPhoneSetting(params) {
    setIsLoading(true);
    const res = await PhoneSettingService.listPhoneSetting(params).catch(console.log);
    if (res) { setListSettingPhone(res) }
    setIsLoading(false);
  }

  async function deletePhoneSetting(params) {
    setIsLoading(true);
    await PhoneSettingService.deletePhoneSetting(params).catch(console.log);
    await getListPhoneSetting(params);
    setIsLoading(false);
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setListSetting(listSetting.map(e => e.key !== name ? e : ({ ...e, number: +value })));
  }

  const handleSave = () => {
    saveData({ listSetting });
  }

  async function saveData(params) {
    setIsLoading(true);
    const res = await SettingService.updateSetting(params).catch(() => setIsLoading(false));
    if (res) {
      sendNotification({
        type: "info",
        message: "Update success",
        color: "success"
      })
    }
    setIsLoading(false);
  }
  
  const handleCloseModal = (isResetList) => {
    setIsShowModal(false);
    if(isResetList) {
      getListPhoneSetting({});
    }
  }

  const handleDeletePhoneSetting = (settingId) => {
    deletePhoneSetting({settingId});
  }

  return (
    <>
      <PageTitle title="Settings" />
      <ModalAdd
        // ref={modalDetailRef}
        // _id={idSelected}
        isShow={isShowModal}
        // handleCloseDetailModal={handleCloseDetailModal}
        handleCloseModal={handleCloseModal}
        // handle
        />
      <div className={classes.formDetail}>
        <Widget
          disableWidgetMenu
          style={{ padding: '0px 0px' }}
        >

          <TableContainer className={classes.tableContainer}>

            <Table>
              <TableBody >
                {listSetting?.length !== 0 && listSetting.map((e, key) => (
                  <TableRow key={key}>
                    <TableCell style={{ width: '50%' }}>{e.name}</TableCell>
                    <TableCell>
                      <TextField
                        id={e.key}
                        name={e.key}
                        type="number"
                        label={e.type === "VND" ? "Unit price" : "Percent"}
                        helperText=""
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={e.number}
                        onChange={handleChangeInput}
                        style={{ width: '85%' }}
                      /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Widget>

        <Widget
          disableWidgetMenu
          style={{ padding: '0px 0px', marginTop: '30px' }}
        >
          <Divider style={{ width: '1000px' }}>Hiển thị số điện thoại KOL</Divider>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '20%' }}>
                  <AddCircleOutlinedIcon 
                  className={classes.iconAddPhoneSetting}
                  onClick={() => {setIsShowModal(true)}}
                  />
                  </TableCell>
                  <TableCell>Số lượng số điện thoại</TableCell>
                  <TableCell>Mức giá</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {listSettingPhone?.length !== 0 && listSettingPhone.map((e, key) => (
                  <TableRow key={key}>
                    <TableCell style={{ width: '20%' }}>
                    <DeleteIcon 
                    onClick={() => {handleDeletePhoneSetting(e._id)}}
                    />
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>{e.quantity}</TableCell>
                    <TableCell>{
                      e.price ? formatMoney(e.price) : ''
                      }</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Widget>
      </div>
      <div className={classes.divSaveData}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
        </Button>
      </div>
    </>
  );
}
