import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  tableBody: {
    // height: '500px'
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: 'black',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  userAction: {
    display: 'flex',
    alignItems: 'center'
  },
  eyeIcon: {
    fontSize: '27px'
  },
  deleteIcon: {
    marginLeft: '10px',
    fontSize: '26px'
  },
  inputSearch: {
    width: '300px'    
  },
  coverTableHead: {
    margin: '5px 40px 16px 24px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    // fontSize: '30px',
    display: 'inline-block',
    color: 'gray',
    '&:hover': {
      color: 'blue'
    },
    // fontSize:'24px'
  },
  customDialog: {
    '& .MuiDialog-paper': {
      width: '480px',
      boxShadow: '0px 0px 15px gray'
    }
  },
  filter: {
    width: '150px'
  },
  link: {
    display: 'flex',
    height: '32px',
    color: 'gray',
    '&:hover': {
      color: 'blue'
    }
  },
}))

