import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  TablePagination,
  TableContainer,
  TableFooter,
  CircularProgress,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  Link
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import * as moment from 'moment';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { sendNotification } from '../../components/Notification/Notification'

// service
import * as PostService from '../../services/post.service';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
// icon
import {
  VisibilityOutlined as VisibilityIcon,
  DeleteOutlined as DeleteIcon,
  VisibilityOutlined as EyeIcon,
  CancelOutlined as CancelIcon,
  CheckCircleOutlined as CheckIcon,
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

const states = {
  APPROVED: "success",
  PENDING: "warning",
  REJECTED: "secondary",
};

export default function ListUser() {
  let timeout;
  const classes = useStyles();
  // const keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key
  const [listPost, setListPost] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [status, setStatus] = useState('ALL');
  const [postSelected, setPostSelected] = useState('');
  const [isApprove, setIsApprove] = useState(false);
  const [params, setParams] = useState({
    search: '',
    page: 0,
    limit: 8,
    status,
  });

  useEffect(() => {
    getListPost(params);
  }, [params])

  useEffect(() => {
    if (params.status !== status) {
      setParams({
        ...params,
        status,
        page: 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  async function getListPost(params) {
    setIsLoading(true);
    const res = await PostService.list({
      ...params,
      page: params.page + 1
    }).catch();

    if (res) {
      setListPost(res.docs || []);
      setTotalDocs(res.totalDocs || 0);
      setTotalPages(res.totalPages);
    }
    // setListUser([]);
    // setTotal(0)
    // console.log('res: ', res);
    setIsLoading(false)
  };

  const handleChangePage = (event, newPage) => {
    // console.log('You have changed the page: ', newPage);
    setParams({
      ...params,
      page: newPage
    });
  };


  const handleLabelDisplayedRows = () => {
    return `Page ${params.page + 1} of ${totalPages}`;
  };

  const handelChangeSearch = (event) => {
    let text = event.target.value;
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setParams({
        ...params,
        search: text,
        page: 0
      })
    }, 1000)
  }

  const handleClose = () => {
    setIsShow(false);
    setPostSelected('');
  }

  const handleApprovePost = (postId) => {
    setIsShow(true);
    setPostSelected(postId);
    setIsApprove(true)
  }

  const handleRejectPost = (postId) => {
    setIsShow(true);
    setPostSelected(postId);
    setIsApprove(false)
  }

  const handleConfirmApprovePost = async () => {
    setIsLoading(true);

    if (!postSelected) {
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await PostService.approve({ _id: postSelected, status: "APPROVED" }).catch();
    if (res) {
      setParams({
        ...params,
        page: 0
      })

      sendNotification({
        type: "info",
        message: "Approved post successfully",
        color: "success"
      })
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleConfirmRejectPost = async () => {
    setIsLoading(true);

    if (!postSelected) {
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await PostService.approve({ _id: postSelected, status: "REJECTED" }).catch();
    if (res) {
      setParams({
        ...params,
        page: 0
      })
      sendNotification({
        type: "info",
        message: "Rejected booking successfully",
        color: "success"
      })
    }

    setIsShow(false);
    setIsLoading(false)
  }

  return (
    <>
      <Dialog
        className={classes.customDialog}
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{
          isApprove ? "Approve this post?" : "Reject this post?"
        }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={isApprove ? handleConfirmApprovePost : handleConfirmRejectPost}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PageTitle title="Post" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer component={Paper}>
              <div className={classes.coverTableHead}>
                <Input className={classes.inputSearch} placeholder="Search here..." onChange={handelChangeSearch} />
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {isLoading && (<div mx="auto"><CircularProgress /></div>)}
                  <FormControl className={classes.filter} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      // size="small"
                      onChange={
                        event => {
                          const { value } = event.target;
                          setStatus(value)
                        }
                      }
                    >
                      <MenuItem value={'ALL'}>ALL</MenuItem>
                      <MenuItem value={'PENDING'}>PENDING</MenuItem>
                      <MenuItem value={'APPROVED'}>APPROVED</MenuItem>
                      <MenuItem value={'REJECTED'}>REJECTED</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                    <TableCell>Creator</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {!isLoading && listPost.length !== 0 && listPost.map(({ _id, title, description, creator, createdAt, status }) => (
                    <TableRow key={_id}>
                      <TableCell className="pl-3 fw-normal">{title || ''}</TableCell>
                      {/* <TableCell>{description || ''}</TableCell> */}
                      <TableCell>{creator?.profile?.fullName || ''}</TableCell>
                      <TableCell>{
                        createdAt ?
                          moment(createdAt).format("DD/MM/YYYY - HH:mm") : ''
                      }</TableCell>
                      <TableCell>
                        <Chip label={status} classes={{ root: classes[states[status]] }} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.userAction}>
                          <div>
                            <Link className={classes.link} to={`/post/${_id}`}>
                              <VisibilityIcon
                                style={{
                                  marginTop: '1.5px',
                                  fontSize: '36px'
                                }}
                                className={classes.eyeIcon} />
                            </Link>
                          </div>

                          {status === 'PENDING' && (
                            <div>
                              <Link className={classes.link}>
                                <CheckIcon
                                  style={{
                                    marginTop: '1.5px',
                                    marginLeft: '10px',
                                    fontSize: '30px',
                                    color: 'green'
                                  }}
                                  onClick={() => handleApprovePost(_id)}
                                />
                              </Link>
                            </div>
                          )}
                          {status === 'PENDING' && (
                            <div>
                              <Link className={classes.link}>
                                <CancelIcon
                                  style={{
                                    marginTop: '1.5px',
                                    marginLeft: '10px',
                                    fontSize: '30px',
                                    color: 'red'
                                  }}
                                  onClick={() => handleRejectPost(_id)}
                                />
                              </Link>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalDocs > 0 && (<TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={3}
                      count={totalDocs}
                      rowsPerPage={params.limit}
                      page={params.page}
                      onChangePage={handleChangePage}
                      labelDisplayedRows={handleLabelDisplayedRows}
                    // SelectProps={{
                    //   inputProps: { 'aria-label': 'rows per page' },
                    //   native: true,
                    // }}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />)}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
