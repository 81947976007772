import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControlLabel,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Modal,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';
import MomentUtils from '@date-io/moment';

// components
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PageTitle from "../../components/PageTitle/PageTitle";
import Divider from "../../components/Divider";
import Dropzone from "../../components/Dropzone";
import Widget from "../../components/Widget/Widget";
import { sendNotification } from '../../components/Notification/Notification'

// service
import * as KolService from '../../services/kol.service';
import * as AreaOfConcernService from '../../services/areaOfConcern.service';
import * as CountryService from '../../services/country.service';

// icon
import {
  Save as SaveIcon,
  LocalAtm as LocalAtmIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

// resources
import dataCountries from "../../resources/countries.json";

// utils
import { formatMoney } from "../../utils";

// variables
import {
  IMAGE_DEFAULT,
  AVATAR_DEFAULT,
  TICK_VERIFIED,
  HOST_IMAGE,
  LITERACY,
  RELATIONSHIP
} from '../../constant/constant';

export default function DetailUser(props) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitiating, setIsInitiating] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isApprove, setIsApprove] = useState(true);

  const [avatar, setAvatar] = useState('');
  const [listCountries, setListCountries] = useState([]);

  const [listPhoneCode, setListPhoneCode] = useState([]);
  const [inputPhoneCode, setInputPhoneCode] = useState('');

  const [inputCountry, setInputCountry] = useState('');
  const [inputLiteracy, setInputLiteracy] = useState('');
  const [inputRelationship, setInputRelationship] = useState('');

  const [imgFront, setImgFront] = useState('');
  const [imgBack, setImgBack] = useState('');
  const [imgFull, setImgFull] = useState([]);
  const [imgContract, setImgContract] = useState([]);

  const [user, setUser] = useState(null);

  const [isOpenLightbox, setIsOpenLigtbox] = useState(false);
  const [imgLightbox, setImgLightbox] = useState('');

  // AOC = Areas Of Concern
  const [listUnselectAOC, setListUnselectAOC] = useState([]);
  const [listSelectedAOC, setListSelectedAOC] = useState([]);

  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  const [province, setProvince] = useState({});
  const [district, setDistrict] = useState({});
  const [ward, setWard] = useState({});

  const [inputProvince, setInputProvince] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');
  const [inputWard, setInputWard] = useState('');


  const [inputManager, setInputManager] = useState('');
  const [listManager, setListManager] = useState([
    'None', 'Kha Cao', 'Tam Nguyen', 'Hien Nguyen', 'Trung Tran', 'Thanh Tran', 'Quy Nguyen'
  ]);

  const [validate, setValidate] = useState({
    fullName: true,
    email: true
  });

  useEffect(() => {
    const userId = props.match.params.id;
    if (userId) { initPage({ userId }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setUser({
      ...user,
      areasOfConcern: listSelectedAOC.map(e => e._id)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSelectedAOC])

  useEffect(() => {
    if (Object.keys(province).length && !isInitiating)
      getListDistrict({ provinceId: province.provinceId })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province])

  useEffect(() => {
    if (Object.keys(district).length && !isInitiating)
      getListWard({ districtId: district.districtId })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district])

  async function initPage(params) {
    setIsInitiating(true);
    setListCountries(dataCountries.map(e => e.name));
    const res = await getDetailUser(params);
    if (res) {
      await getListAreasOfConcern(res);
      await getListProvince(res);
      await getListPhoneCode();
    }
    setIsInitiating(false)
  }

  async function getDetailUser(params) {
    const res = await KolService.getDetail({
      type: 'KOL',
      ...params
    }).catch();
    if (res) {
      setUser(res);
      setInputCountry(res.profile?.country || '');
      setAvatar(res.profile?.imgPortrait || '')
      setImgFront(res.profile?.imgFront || '')
      setImgBack(res.profile?.imgBack || '')
      setImgFull(res.profile?.imgFull || [])
      setImgContract(res.imgContract || [])
      setValidate({
        ...validate,
        fullName: !!res.profile?.fullName,
        email: !!res.email
      })
    }
    return res;
  };

  async function getListPhoneCode(params) {
    const res = await CountryService.listPhone(params).catch();
    if (res) {
      setListPhoneCode(res);
    }
  };

  async function getListProvince(user) {
    const listProv = await CountryService.listProvince({}).catch();
    if (!listProv) return;
    setListProvince(listProv);

    // Province
    if (!user.profile?.province) return;
    const provinceInArr = listProv.find(e => e.name === user.profile?.province);
    if (!provinceInArr) return;
    setProvince(provinceInArr);

    // District
    if (!user.profile?.district) return;
    const listDis = await CountryService.listDistrict({ provinceId: provinceInArr.provinceId }).catch();
    if (!listDis) return;
    setListDistrict(listDis);

    const districtInArr = listDis.find(e => e.name === user.profile?.district);
    if (!districtInArr) return;
    setDistrict(districtInArr);

    // Ward
    if (!user.profile?.ward) return;
    const listWa = await CountryService.listWard({ districtId: districtInArr.districtId }).catch();
    if (!listWa) return;
    setListWard(listWa);

    const wardInArr = listWa.find(e => e.name === user.profile?.ward);
    setWard(wardInArr);
  };

  async function getListDistrict(params) {
    setIsLoading(true);
    const res = await CountryService.listDistrict(params).catch();
    if (res) {
      setListDistrict(res);
    }
    setIsLoading(false)
  };

  async function getListWard(params) {
    setIsLoading(true);
    const res = await CountryService.listWard(params).catch();
    if (res) {
      setListWard(res);
    }
    setIsLoading(false)
  };

  async function getListAreasOfConcern(params) {
    setIsLoading(true);
    let res = await AreaOfConcernService.list(params).catch();
    if (res) {
      let listTempAOC = [];
      let listTempSelectedAOC = [];

      for (let aoc of res) {
        if (!params.areasOfConcern.includes(aoc._id)) {
          listTempAOC.push(aoc);
        } else {
          listTempSelectedAOC.push(aoc);
        }
      }

      setListUnselectAOC(listTempAOC);
      setListSelectedAOC(listTempSelectedAOC);
    }
    setIsLoading(false)
    return params;
  };

  const handleChangeGender = event => {
    setUser({
      ...user,
      profile: {
        ...user.profile,
        gender: event.target.value
      }
    })
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    if (Object.keys(validate).includes(name)) {
      setValidate({
        ...validate,
        [name]: !!value.trim()
      })
    }

    const profileFields = [
      'userNameFacebook',
      'userNameInstagram',
      'fullName',
      'address',
      'gender',
      'birthDay',
      'country',
      'cardID',
      'avatar',
      'imgPortrait',
      'imgFront',
      'imgBack',
      'imgFull',
      'hometown',
      'literacy',
      'marital',
      'describe',
      'link',
      'district',
      'ward',
      'province'
    ];

    const rankFields = [
      'rankType',
      'view',
      'comment',
      'share',
      'like',
      'follow',
      'price'
    ];

    if (profileFields.includes(name)) {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          [name]: value
        }
      })

      return;
    }

    if (rankFields.includes(name)) {
      setUser({
        ...user,
        rank: {
          ...user.rank,
          [name]: value
        }
      })

      return;
    }

    setUser({
      ...user,
      [name]: value
    })
  }

  const handleChangeDate = (date) => {
    if (date) {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          birthDay: date
        }
      })
      return;
    }
  }

  const handleChangePhoneCode = (event, newValue) => {
    if (newValue) {
      setUser({
        ...user,
        codePhone: newValue.mobileCode
      })
      return;
    }
  }

  const handleChangeProvince = (event, newValue) => {
    if (newValue) {
      setProvince(newValue)

      setDistrict({})
      setListDistrict([])

      setWard({})
      setListWard([])

      setUser({
        ...user,
        profile: {
          ...user.profile,
          province: newValue.name,
          district: '',
          ward: ''
        }
      })
    }
  }

  const handleChangeDistrict = (event, newValue) => {
    if (newValue) {
      setDistrict(newValue)
      setWard({})
      setListWard([])
      setUser({
        ...user,
        profile: {
          ...user.profile,
          district: newValue.name,
          ward: ''
        }
      })
    }
  }

  const handleChangeWard = (event, newValue) => {
    if (newValue) {
      setWard(newValue)
      setUser({
        ...user,
        profile: {
          ...user.profile,
          ward: newValue.name
        }
      })
    }
  }

  const handleChangeCountry = (event, newValue) => {
    if (newValue) {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          country: newValue
        }
      })

      return;
    }
  }

  const handleChangeLiteracy = (event, newValue) => {
    if (newValue) {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          literacy: newValue
        }
      })

      return;
    }
  }

  const handleChangeRelationShip = (event, newValue) => {
    if (newValue) {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          marital: newValue
        }
      })

      return;
    }
  }

  const handleChangeManager = (event, newValue) => {
    if (newValue) {
      setUser({
        ...user,
        managedBy: newValue === 'None' ? '' : newValue
      })

      return;
    }
  }

  const handleUploadAvatar = (file) => {
    // console.log('file avatar : ', file);
    setAvatar(file.url);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        imgPortrait: file.url,
        avatar: file.url
      }
    })
  }

  const handleUploadFrontID = (file) => {
    setImgFront(file[0]?.url);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        imgFront: file[0]?.url
      }
    })
  }

  const handleUploadBackID = (file) => {
    setImgBack(file[0]?.url);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        imgBack: file[0]?.url
      }
    })
  }

  const handleUploadImagesFull = (files) => {
    files = files.map(e => e.url);
    const mergeImages = [
      ...user.profile?.imgFull || [],
      ...files
    ]
    setImgFull(mergeImages);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        imgFull: mergeImages
      }
    })
  }

  const handleUploadContractImage = (files) => {
    files = files.map(e => e.url);
    const mergeImages = [
      ...user.imgContract || [],
      ...files
    ]
    setImgContract(mergeImages);
    setUser({
      ...user,
      imgContract: mergeImages
    })
  }

  const handleRemoveBonusImage = (index) => {
    const newImageList = (user.profile?.imgFull || []).filter((e, i) => i !== index);
    setImgFull(newImageList);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        imgFull: newImageList
      }
    })
  }

  const handleRemoveContractImage = (index) => {
    const newImageList = (user.imgContract || []).filter((e, i) => i !== index);
    setImgContract(newImageList);
    setUser({
      ...user,
      imgContract: newImageList
    })
  }

  const handleAddAOC = (element) => {
    const aoc = listSelectedAOC.find(e => e._id === element._id);
    if (!aoc) {
      setListSelectedAOC([
        ...listSelectedAOC,
        { ...element }
      ])

      setListUnselectAOC([
        ...listUnselectAOC.filter(e => e._id !== element._id)
      ])
    }
  }

  const handleDeleteAOC = (id) => {
    const aoc = listSelectedAOC.find(e => e._id === id);
    if (aoc) {
      setListSelectedAOC([
        ...listSelectedAOC.filter(e => e._id !== id)
      ])

      setListUnselectAOC([
        ...listUnselectAOC,
        { ...aoc }
      ])
    }
  }

  // Light box handler
  const renderLightbox = () => (
    <div className={classes.paper}>
      <img
        className={classes.imgBorder}
        title="Lightbox"
        alt="Lightbox"
        src={
          imgLightbox
            ? `${HOST_IMAGE}${imgLightbox}`
            : IMAGE_DEFAULT
        }
      />
    </div>
  );

  const handleOpenLightbox = (src) => {
    setImgLightbox(src)
    setIsOpenLigtbox(true);
  };

  const handleCloseLightbox = () => {
    setIsOpenLigtbox(false);
  };

  // End Light box handler

  const handleSave = () => {
    saveData(user);
  }

  const saveData = (data) => {
    setIsLoading(true);
    KolService.updateInfo(data)
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Update success",
            color: "success"
          })
        }
      })
      .catch(() => setIsLoading(false));
  }

  const handleConfirmApproveIdCard = () => {
    setIsLoading(true);
    KolService.approveIdCard({
      _id: user._id,
      status: 'APPROVED'
    })
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Approve success",
            color: "success"
          })
          return getDetailUser({ userId: user._id });
        }
      })
      .catch(() => setIsLoading(false));
    setIsOpenDialog(false);
  }

  const handleConfirmRejectIdCard = () => {
    setIsLoading(true);
    KolService.approveIdCard({
      _id: user._id,
      status: 'REJECT'
    })
      .then(res => {
        setIsLoading(false);
        if (res) {
          sendNotification({
            type: "info",
            message: "Reject success",
            color: "success"
          })
          return getDetailUser({ userId: user._id });
        }
      })
      .catch(() => setIsLoading(false));
    setIsOpenDialog(false);
  }

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  }

  const handleApproveIdCard = () => {
    setIsOpenDialog(true);
    setIsApprove(true)
  }

  const handleRejectIdCard = () => {
    setIsOpenDialog(true);
    setIsApprove(false)
  }

  return user && !isInitiating && (
    <>
      <Modal
        open={isOpenLightbox}
        onClose={handleCloseLightbox}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {renderLightbox()}
      </Modal>

      <Dialog
        className={classes.customDialog}
        open={isOpenDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{
          isApprove ? "Approve this KOL?" : "Reject this KOL?"
        }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button
            onClick={isApprove ? handleConfirmApproveIdCard : handleConfirmRejectIdCard}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <PageTitle title="KOL Detail" />
      <div className={classes.formDetail}>
        <Widget
          disableWidgetMenu
        // style={{width: '1100px'}}
        >
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider style={{ width: '450px' }}>KOL information</Divider>
              </Grid>
              <Grid item container xs={8}>
                <Grid item xs={12}>
                  <TextField
                    id="fullName"
                    name="fullName"
                    label="Fullname"
                    helperText=""
                    fullWidth
                    margin="normal"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    variant="outlined"
                    size="medium"
                    value={user.profile?.fullName || ''}
                    onChange={handleChangeInput}
                    error={!validate.fullName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={user.email || ''}
                    onChange={handleChangeInput}
                    style={{ marginTop: '24px' }}
                    error={!validate.email}
                  />
                </Grid>
                <Grid item xs={4} style={{
                  marginRight: '25px',
                  maxWidth: '29.333333%'
                }}
                >
                  <Autocomplete
                    value={user || ''}
                    onChange={handleChangePhoneCode}
                    inputValue={inputPhoneCode}
                    onInputChange={(event, newInputValue) => {
                      setInputPhoneCode(newInputValue);
                    }}
                    id="phoneCode"
                    options={listPhoneCode}
                    getOptionLabel={option => option.mobileCode || user.codePhone || ''}
                    getOptionSelected={option => user && option.mobileCode === user.codePhone}
                    style={{ marginTop: '24px', marginRight: '0px' }}
                    renderInput={(params) => (<TextField {...params} label="Phone Code" variant="outlined" />)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={user.phone || ''}
                    onChange={handleChangeInput}
                    style={{ marginTop: '24px' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.avatarCover}>
                  {user.status === "APPROVED" && (
                    <img
                      className={classes.tickVerified}
                      src={TICK_VERIFIED}
                      alt="tick-verified"
                    />
                  )}
                  <Avatar
                    alt="Avatar"
                    src={
                      avatar
                        ? `${HOST_IMAGE}${avatar}`
                        : AVATAR_DEFAULT}
                    className={classes.avatar}
                    onClick={() => { handleOpenLightbox(avatar) }}
                  />
                  <Dropzone
                    handleUploadFile={handleUploadAvatar}
                    style={{ marginTop: '10px' }}
                    limit={1}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="birthDay"
                    name="birthDay"
                    label="Date of birth"
                    value={user.profile?.birthDay || '1990-01-01'}
                    onChange={handleChangeDate}
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    allowKeyboardControl={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={user.profile?.country || ''}
                  onChange={handleChangeCountry}
                  inputValue={inputCountry}
                  onInputChange={(event, newInputValue) => {
                    setInputCountry(newInputValue);
                  }}
                  id="country"
                  options={listCountries}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: '7px' }}>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender"
                    onChange={handleChangeGender}
                  >
                    <FormControlLabel checked={user.profile?.gender === "FEMALE"} value="FEMALE" control={<Radio />} label="Female" />
                    <FormControlLabel checked={user.profile?.gender === "MALE"} value="MALE" control={<Radio />} label="Male" />
                    <FormControlLabel checked={user.profile?.gender === "OTHER"} value="OTHER" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={province || ''}
                  onChange={handleChangeProvince}
                  inputValue={inputProvince}
                  onInputChange={(event, newInputValue) => {
                    setInputProvince(newInputValue);
                  }}
                  id="province"
                  options={listProvince}
                  getOptionLabel={option => option.name || user.profile?.province || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Province" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={district || ''}
                  onChange={handleChangeDistrict}
                  inputValue={inputDistrict}
                  onInputChange={(event, newInputValue) => {
                    setInputDistrict(newInputValue);
                  }}
                  id="district"
                  options={listDistrict}
                  getOptionLabel={option => option.name || user.profile?.district || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="District" variant="outlined" />}
                // disabled={!listDistrict.length}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={ward || ''}
                  onChange={handleChangeWard}
                  inputValue={inputWard}
                  onInputChange={(event, newInputValue) => {
                    setInputWard(newInputValue);
                  }}
                  id="ward"
                  options={listWard}
                  getOptionLabel={option => option.name || user.profile?.ward || ''}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Ward" variant="outlined" />}
                // disabled={!listWard.length}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.profile?.address || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={user.profile?.literacy || ''}
                  onChange={handleChangeLiteracy}
                  inputValue={inputLiteracy}
                  onInputChange={(event, newInputValue) => {
                    setInputLiteracy(newInputValue);
                  }}
                  id="literacy"
                  options={LITERACY}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Literacy" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={user.profile?.marital || ''}
                  onChange={handleChangeRelationShip}
                  inputValue={inputRelationship}
                  onInputChange={(event, newInputValue) => {
                    setInputRelationship(newInputValue);
                  }}
                  id="marital"
                  options={RELATIONSHIP}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Relationship" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={user.managedBy || ''}
                  onChange={handleChangeManager}
                  inputValue={inputManager}
                  onInputChange={(event, newInputValue) => {
                    setInputManager(newInputValue);
                  }}
                  id="managedBy"
                  options={listManager}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Manager" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipInput
                  className={classes.chipContainerOverride}
                  value={listSelectedAOC.map(e => ({ value: e._id, text: e.name }))}
                  dataSourceConfig={{ value: 'value', text: 'text' }}
                  chipRenderer={({ value, text }, key) => (
                    <Chip
                      key={key}
                      className={classes.chipSuggest}
                      label={text}
                      color="primary"
                      onDelete={() => { handleDeleteAOC(value) }}
                    />
                  )}
                  label='Areas of concern'
                  size="medium"
                  helperText=""
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled
                />
                <div className={classes.chipSuggestCover}>
                  {listUnselectAOC && listUnselectAOC.map((e, i) => (
                    <Chip
                      key={i}
                      className={classes.chipSuggest}
                      label={e.name}
                      color="primary"
                      clickable
                      onClick={() => { handleAddAOC(JSON.parse(JSON.stringify(e))) }}
                    />
                  ))
                  }
                </div>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="userNameFacebook"
                  name="userNameFacebook"
                  label="Username Facebook"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.profile?.userNameFacebook || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="userNameInstagram"
                  name="userNameInstagram"
                  label="Username Instagram"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.profile?.userNameInstagram || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextareaAutosize 
                aria-label="Description" 
                minRows={9} 
                placeholder="Minimum 3 rows" 
                style={{width: '100%'}}
              /> */}
                <TextField
                  id="describe"
                  name="describe"
                  label="Description"
                  helperText=""
                  fullWidth
                  multiline={true}
                  rows={9}
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  value={user.profile?.describe || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ width: '480px' }}>Verify Information</Divider>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.cardIDCover}>
                  <img
                    className={classes.imgBorder}
                    title="Card ID Front"
                    alt="Card ID Front"
                    style={{ width: '100%', height: '300px' }}
                    src={
                      imgFront
                        ? `${HOST_IMAGE}${imgFront}`
                        : IMAGE_DEFAULT
                    }
                    onClick={() => { handleOpenLightbox(imgFront) }}
                  />
                  <Dropzone
                    buttonLabel={'Upload front image'}
                    handleUploadFile={handleUploadFrontID}
                    style={{ marginTop: '10px' }}
                    limit={1}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.cardIDCover}>
                  <img
                    className={classes.imgBorder}
                    title="Card ID Back"
                    alt="Card ID Back"
                    style={{ width: '100%', height: '300px' }}
                    src={
                      imgBack
                        ? `${HOST_IMAGE}${imgBack}`
                        : IMAGE_DEFAULT
                    }
                    onClick={() => { handleOpenLightbox(imgBack) }}
                  />
                  <Dropzone
                    buttonLabel={'Upload back image'}
                    handleUploadFile={handleUploadBackID}
                    style={{ marginTop: '10px' }}
                    limit={1}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.imageListCover}>
                  <div className={classes.imageList}>
                    {
                      imgFull.length === 0 ? (
                        <span>No Images</span>
                      ) : (

                        imgFull.map((src, i) => (
                          <div key={i} className={classes.imageCover}>
                            <span>
                              <CancelIcon
                                onClick={() => handleRemoveBonusImage(i)}
                              />
                            </span>
                            <img
                              className={classes.imgBorder}
                              title="Images"
                              alt="Images"
                              src={
                                src
                                  ? `${HOST_IMAGE}${src}`
                                  : IMAGE_DEFAULT
                              }
                              onClick={() => { handleOpenLightbox(src) }}
                            />
                          </div>
                        ))

                      )
                    }
                  </div>
                  <Dropzone
                    buttonLabel={'Upload images'}
                    handleUploadFile={handleUploadImagesFull}
                    style={{ marginTop: '10px' }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ width: '140px' }}>Rank</Divider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="rankType"
                  name="rankType"
                  label="Rank"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.rankType || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="price"
                  name="price"
                  label="Price"
                  type="number"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.price || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="follow"
                  name="follow"
                  label="Follow"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.follow || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="like"
                  name="like"
                  label="Like"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.like || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="share"
                  name="share"
                  label="Share"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.share || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="comment"
                  name="comment"
                  label="Comment"
                  helperText=""
                  fullWidth
                  margin="normal"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  variant="outlined"
                  size="medium"
                  value={user.rank?.comment || ''}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ width: '140px' }}>Contract</Divider>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={classes.imageListCover}
                  style={{ marginTop: '10px' }}
                >
                  <div className={classes.imageList}>
                    {
                      imgContract.length === 0 ? (
                        <span>No Images</span>
                      ) : (

                        imgContract.map((src, i) => (
                          <div key={i} className={classes.imageCover}>
                            <span>
                              <CancelIcon
                                onClick={() => handleRemoveContractImage(i)}
                              />
                            </span>
                            <img
                              className={classes.imgBorder}
                              title="Contract"
                              alt="Contract"
                              src={
                                src
                                  ? `${HOST_IMAGE}${src}`
                                  : IMAGE_DEFAULT
                              }
                              onClick={() => { handleOpenLightbox(src) }}
                            />
                          </div>
                        ))

                      )
                    }
                  </div>
                  <Dropzone
                    buttonLabel={'Upload contract'}
                    handleUploadFile={handleUploadContractImage}
                    style={{ marginTop: '10px' }}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </Widget>
      </div>
      <div className={classes.divBox}>
        <div
          className={classes.divAmount}
        >
          <Chip
            style={{
              padding: '20px 15px',
              fontSize: '20px',
              borderWidth: 'medium',
              minWidth: '200px',
              justifyContent: 'space-between'
            }}
            size="medium"
            icon={
              <LocalAtmIcon style={{
                fontSize: '32px',
                marginRight: '10px'
              }} />
            }
            label={`${formatMoney(user.amount || 0)} VND`}
            color="primary"
            variant="outlined"
          />
        </div>
        {(user?.status === 'PENDING' || user?.status === 'REJECT') && (
          <>
            <Button
              className={classes.buttonApprove}
              variant="contained"
              color="primary"
              onClick={handleApproveIdCard}
              startIcon={<CheckIcon />}
              disabled={isLoading}
            >
              Approve
            </Button>
            <Button
              className={classes.buttonReject}
              variant="contained"
              color="secondary"
              onClick={handleRejectIdCard}
              startIcon={<CancelIcon />}
              disabled={isLoading}
            >
              Reject
            </Button>
          </>
        )}
        <Button
          className={classes.buttonSave}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={isLoading || !Object.values(validate).every(e => e)}
        >
          Save
        </Button>
      </div>
    </>
  );
}
