import AxiosBase from './axiosClient';
// import * as moment from 'moment';

export const list = async function(params) {
    const fromDate = params.fromDate?.format('DD/MM/YYYY');
    const toDate = params.toDate?.format('DD/MM/YYYY');
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/transaction/list?page=${params.page}&limit=${params.limit}&fromDate=${fromDate}&toDate=${toDate}`);
    return res.data;
}