import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableFooter,
  CircularProgress,
  Input,
  Tabs,
  Tab,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip
} from "@material-ui/core";
import {
  Link
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { sendNotification } from '../../components/Notification/Notification'

// service
import * as BookingService from '../../services/booking.service';

// icon
import {
  VisibilityOutlined as EyeIcon,
  CancelOutlined as CancelIcon,
  CheckCircleOutlined as CheckIcon,
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

// utils
import { formatMoney } from "../../utils";

export default function ListBooking() {
  let timeout;
  const classes = useStyles();

  const [listStatus, setListStatus] = useState([
    { name: 'Pending', key: 'PENDING', active: true },
    { name: 'Pending KOL', key: 'PENDING_KOL', active: false },
    { name: 'Finding', key: 'FINDING', active: false },
    { name: 'Approved', key: 'APPROVED', active: false },
    { name: 'Processing', key: 'PROCESSING', active: false },
    { name: 'Completed', key: 'COMPLETED', active: false },
    { name: 'Canceled', key: 'CANCELED', active: false },
  ]);

  const [countBooking, setCountBooking] = useState({
    PENDING: 0,
    PENDING_KOL: 0,
    FINDING: 0,
    APPROVED: 0,
    PROCESSING: 0,
    COMPLETED: 0,
    CANCELED: 0
  });

  // const [inputSearch, setInputSearch] = useState('');
  const [listBooking, setListBooking] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasChange, setHasChange] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [bookingSelected, setBookingSelected] = useState('');
  const [params, setParams] = useState({
    search: '',
    page: 0,
    limit: 8,
    status: 'PENDING',
    type: 'KOL'
  });

  useEffect(() => {
    getCountBooking();
  }, [hasChange])

  useEffect(() => {
    getListBooking(params);
  }, [params])

  async function getListBooking(params) {
    setIsLoading(true);
    const res = await BookingService.listBooking({
      ...params,
      page: params.page + 1
    }).catch();

    if (res) {
      setListBooking(res.docs || []);
      setTotalDocs(res.totalDocs || 0);
      setTotalPages(res.totalPages);
    }

    setIsLoading(false)
  };

  async function getCountBooking(params) {
    setIsLoading(true);
    const res = await BookingService.countBookingByStatus().catch();
    if (res) {
      setCountBooking({
        ...res
      });
    }

    setIsLoading(false)
  };

  const handleChangePage = (event, newPage) => {
    // console.log('You have changed the page: ', newPage);
    setParams({
      ...params,
      page: newPage
    });
  };

  const handleLabelDisplayedRows = () => {
    return `Page ${params.page + 1} of ${totalPages}`;
  };

  const handelChangeSearch = (event) => {
    const text = event?.target?.value || '';

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setParams({
        ...params,
        search: text,
        page: 0
      })
    }, 1000);
  }

  const handleChangeTab = (indexNeedChange) => {
    setListStatus(listStatus.map((e, index) => {
      if (index === indexNeedChange) {
        setParams({
          ...params,
          status: e.key,
          search: '',
          page: 0
        })

        document.getElementById('inputSearch').value = '';
      }

      return { ...e, active: index === indexNeedChange }
    }))
  };

  const handleConfirmApproveBooking = async () => {
    setIsLoading(true);

    if (!bookingSelected) {
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await BookingService.approveBooking({ workID: bookingSelected }).catch();
    if (res) {
      setParams({
        ...params,
        page: 0
      })
      setHasChange(hasChange + 1);
      sendNotification({
        type: "info",
        message: "Approved booking successfully",
        color: "success"
      })
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleConfirmCancelBooking = async () => {
    setIsLoading(true);

    if (!bookingSelected) {
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });
      setIsShow(false);
      setIsLoading(false);
      return;
    }

    const res = await BookingService.cancelBooking({ workID: bookingSelected }).catch();
    if (res) {
      setParams({
        ...params,
        page: 0
      })
      setHasChange(hasChange + 1);
      sendNotification({
        type: "info",
        message: "Canceled booking successfully",
        color: "success"
      })
    }

    setIsShow(false);
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsShow(false);
    setBookingSelected('');
  }

  const handleApproveBooking = (bookingId) => {
    setIsShow(true);
    setBookingSelected(bookingId);
    setIsApprove(true)
  }

  const handleCancelBooking = (bookingId) => {
    setIsShow(true);
    setBookingSelected(bookingId);
    setIsApprove(false)
  }

  const renderTypeBooking = (type) => {
    switch(type) {
      case "WORK_BOOKING": return 'Now';
      case "WORK_FAST": return 'Fast';
      default: return 'Normal'
    }
  }

  return (
    <>
      <Dialog
        className={classes.customDialog}
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{
          isApprove ? "Approve this booking?" : "Cancel this booking?"
        }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={isApprove ? handleConfirmApproveBooking : handleConfirmCancelBooking}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PageTitle title="Booking" />
      <Tabs
        className={classes.listTabStatus}
        value={false}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="on"
        onChange={(e, index) => { handleChangeTab(index) }}
      >
        {listStatus.map(status => (

          <Tab
            key={status.key}
            className={`${classes.tab} ${status.active && classes.tabActive}`}
            disabled={status.active}
            label={
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                badgeContent={countBooking[status.key] || 0}
                max={9999}
                color="primary"
              >
                {status.name}
              </Badge>
            }
          />
        ))}
      </Tabs>
      <Grid container spacing={4} className={classes.overridedClass}>
        <Grid item xs={12}>
          <Widget noBodyPadding noHeaderPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer component={Paper} boxShadow={1}>
              <div className={classes.coverTableHead}>
                <Input
                  id="inputSearch"
                  className={classes.inputSearch}
                  placeholder="Search here..."
                  onChange={(event) => {
                    handelChangeSearch(event);
                    // setInputSearch(event?.target?.value || '');
                    // dùng setInputSearch ở đây thì setTimeOut ở 
                    // trong function handelChangeSearch ko chạy được
                    // ???????
                  }
                  }
                // value={inputSearch} ?????
                />
                {isLoading && (<div mx="auto"><CircularProgress /></div>)}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: '20%'}}>Creator</TableCell>
                    <TableCell style={{width: '40%'}}>Title</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {!isLoading &&
                    listBooking.length !== 0 &&
                    listBooking.map(({ _id, creator, title, price, priceStart, priceEnd, type }) => (
                      <TableRow key={_id}>
                        <TableCell style={{width: '20%'}} className="pl-3 fw-normal">{creator?.profile?.fullName || ''}</TableCell>
                        <TableCell style={{width: '40%'}} >{title || ''}</TableCell>
                        <TableCell>{
                          price ? formatMoney(price)
                            : (
                              priceEnd ?
                                `${formatMoney(priceStart)} - ${formatMoney(priceEnd)}`
                                : ''
                            )
                        }</TableCell>
                        <TableCell>
                          <Chip label={renderTypeBooking(type)} classes={{ root: classes['warning'] }} />
                        </TableCell>
                        <TableCell>
                          <div className={classes.userAction}>
                            <div>
                              <Link
                                className={classes.link}
                                to={`/booking/${_id}`}
                              >
                                <EyeIcon style={{ fontSize: '36px' }} className={classes.eyeIcon} />
                              </Link>
                            </div>
                            {params.status === 'PENDING' && (
                              <div>
                                <Link className={classes.link}>
                                  <CheckIcon
                                    style={{
                                      marginTop: '1.5px',
                                      marginLeft: '10px',
                                      fontSize: '30px',
                                      color: 'green'
                                    }}
                                    onClick={() => handleApproveBooking(_id)}
                                  />
                                </Link>
                              </div>
                            )}
                            {params.status === 'PENDING' && (
                              <div>
                                <Link className={classes.link}>
                                  <CancelIcon
                                    style={{
                                      marginTop: '1.5px',
                                      marginLeft: '10px',
                                      fontSize: '30px',
                                      color: 'red'
                                    }}
                                    onClick={() => handleCancelBooking(_id)}
                                  />
                                </Link>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalDocs > 0 && (<TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={2}
                      count={totalDocs}
                      rowsPerPage={params.limit}
                      page={params.page}
                      onChangePage={handleChangePage}
                      labelDisplayedRows={handleLabelDisplayedRows}
                    />)}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
