import AxiosBase from './axiosClient';

export const countBookingByStatus = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(
        `cms/work/count`
        );
    return res.data;
}

export const listBooking = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(
        `cms/work/list?page=${params.page}&limit=${params.limit}&status=${params.status}&search=${params.search}`
        );
    return res.data;
}

export const detailBooking = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(
        `cms/work/${params.bookingId}`
        );
    return res.data;
}

export const approveBooking = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/work/approve`, params);
    return res.data;
}

export const cancelBooking = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/work/cancel`, params);
    return res.data;
}

export const updateBooking = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/work/${params._id}`, params);
    return res.data;
}