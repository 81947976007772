
export function formatMoney(num) {
    if (num || num === 0) {
        try {
            return num.toLocaleString('en-US', {
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                currency: 'VND',
            });
        } catch (error) {
            return '';
        }
    }
    return '';
}
