import React from "react";
import {
  Avatar,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";

// utils
import { formatMoney } from "../../../../utils";

// variables
import {
  AVATAR_DEFAULT,
  HOST_IMAGE,
  TICK_VERIFIED
} from '../../../../constant/constant';

// css
import { useStyles } from "./style";

export default function Candidate(props) {
  const classes = useStyles();

  return (
    <>
      <List
        style={{
          padding: '10px 150px',
          width: '100%',
          maxHeight: '400px',
          overflowY: 'scroll'
        }}
      >
        {
          props.candidates.map((candidate, i) => (
            <>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      fontSize: '18px'
                    }}
                    to={`/kol/${candidate.kolID?._id || ''}`}
                    target="_blank"
                    rel="noopener noreferrer" >
                    <Avatar
                      style={{ width: '80px', height: '80px' }}
                      alt={candidate.kolID?.profile?.fullName || ''}
                      src={
                        candidate.kolID?.profile?.avatar
                          ? `${HOST_IMAGE}${candidate.kolID?.profile?.avatar}`
                          : AVATAR_DEFAULT
                      }
                    />
                  </Link>
                </ListItemAvatar>
                <ListItemText
                  style={{
                    padding: '0px 20px',
                  }}
                  primary={
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: '#536DFE',
                        fontSize: '18px',
                      }}
                      color="primary"
                      to={`/kol/${candidate.kolID?._id || ''}`}
                      target="_blank"
                      rel="noopener noreferrer" >
                      {candidate.kolID?.profile?.fullName || ''}
                    </Link>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        <p
                          style={{
                            marginTop: '8px',
                            marginBottom: '0px',
                            cursor: 'default'
                          }}
                        >
                          {`${candidate.rankTypeKOL || 'No Rank'} 
                          - ${formatMoney(candidate.priceKOL) || 0} VND`}
                        </p>
                        <p
                          style={{
                            marginTop: '0px',
                            marginBottom: '0px',
                            cursor: 'default'
                          }}
                        >
                          {`${candidate.kolID?.profile?.province ?
                            candidate.kolID?.profile?.province + ',' : ''}
                         ${candidate.kolID?.profile?.country || 'No Country'}`}
                        </p>
                      </Typography>
                    </React.Fragment>
                  }
                />
                {candidate?.kolID?._id === props.kolID && (
                  <div >
                    <img
                      className={classes.tickVerified}
                      src={TICK_VERIFIED}
                      alt="tick-verified"
                    />
                  </div>
                )}
              </ListItem>
              {i !== props.candidates.length - 1 && (
                <Divider
                  variant="inset"
                  component="li"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </>
          ))
        }
      </List>
    </>
  );
}
