import AxiosBase from './axiosClient';

export const login = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.post('cms/login',params);
    return res.data;
}

export const getCurrentUser = async function() {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get('cms/current-admin');
    return res.data;
}