import AxiosBase from './axiosClient';

export const list = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/post/list?page=${params.page}&limit=${params.limit}&status=${params.status}&search=${params.search}`);
    return res.data;
}

export const detail = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/post/${params._id}`);
    return res.data;
}

export const approve = async function(params) {
    const {_id, ...data} = params;
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/post/${_id}`, data);
    return res.data;
}

export const update = async function(params) {
    const {_id, ...data} = params;
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.patch(`cms/post/${_id}`, data);
    return res.data;
}
