import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  TablePagination,
  TableContainer,
  TableFooter,
  CircularProgress,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  Link
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { sendNotification } from '../../components/Notification/Notification'

// service
import * as KolService from '../../services/kol.service';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
// icon
import {
  VisibilityOutlined as VisibilityIcon,
  DeleteOutlined as DeleteIcon
} from "@material-ui/icons";

// css
import { useStyles } from "./style";

const states = {
  APPROVED: "success",
  PENDING: "warning",
  REJECT: "secondary",
};

export default function ListUser() {
  let timeout;
  const classes = useStyles();
  // const keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key
  const [listUser, setListUser] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [status, setStatus] = useState('ALL');
  const [params, setParams] = useState({
    search: '',
    page: 0,
    limit: 8,
    status,
    type: 'KOL'
  });

  useEffect(() => {
    getListUser(params);
  }, [params])

  useEffect(() => {
    if(params.status !== status) {
      setParams({
        ...params,
        status,
        page: 0
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  async function getListUser(params) {
    setIsLoading(true);
    const res = await KolService.list({
      ...params,
      page: params.page + 1
    }).catch();

    if (res) {
      setListUser(res.docs || []);
      setTotalDocs(res.totalDocs || 0);
      setTotalPages(res.totalPages);
    }
    // setListUser([]);
    // setTotal(0)
    // console.log('res: ', res);
    setIsLoading(false)
  };

  function handleDeleteClick(id) {
    setIdDelete(id);
    setIsShow(true);
  }

  const handleChangePage = (event, newPage) => {
    // console.log('You have changed the page: ', newPage);
    setParams({
      ...params,
      page: newPage
    });
  };


  const handleLabelDisplayedRows = () => {
    return `Page ${params.page + 1} of ${totalPages}`;
  };

  const handelChangeSearch = (event) => {
    let text = event.target.value;
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setParams({
        ...params,
        search: text,
        page: 0
      })
    }, 1000)
  }

  const handleClose = () => {
    setIsShow(false);
    setIdDelete('');
  }

  const handleConfirmDelete = async () => {
    if(!idDelete) {
      // noti
      sendNotification({
        type: "error",
        message: "Something went wrong!! Please try again",
        color: "error"
      });

      // setIsShow(false);
      return;
    }

    const res = await KolService.deleteKol({_id: idDelete}).catch();
    if(res) {
      setIsShow(false);
      setIdDelete('');
      setParams({
        ...params,
        page: 0
      })

      sendNotification({
        type: "info",
        message: "Delete success",
        color: "success"
      })
    }
  }

  return (
    <>
      <Dialog
        className={classes.customDialog}
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete this user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will not be able to recover it !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <PageTitle title="KOL" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer component={Paper}>
              <div className={classes.coverTableHead}>
                <Input className={classes.inputSearch} placeholder="Search here..." onChange={handelChangeSearch} />
                <div 
                  style={{
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                {isLoading && (<div mx="auto"><CircularProgress /></div>)}
                <FormControl className={classes.filter} variant="outlined">
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    // size="small"
                    onChange={ 
                      event => { 
                        const { value } = event.target;
                        setStatus(value)
                      }
                    }
                  >
                    <MenuItem value={'ALL'}>ALL</MenuItem>
                    <MenuItem value={'PENDING'}>PENDING</MenuItem>
                    <MenuItem value={'APPROVED'}>APPROVED</MenuItem>
                    <MenuItem value={'REJECT'}>REJECTED</MenuItem>
                  </Select>
                </FormControl>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Rank</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {!isLoading && listUser.length !== 0 && listUser.map(({ _id, profile, email, phone, rank, status }) => (
                    <TableRow key={_id}>
                      <TableCell className="pl-3 fw-normal">{profile?.fullName || ''}</TableCell>
                      <TableCell>{email || ''}</TableCell>
                      <TableCell>{phone || ''}</TableCell>
                      <TableCell>{rank?.rankType || ''}</TableCell>
                      <TableCell>
                        <Chip label={status} classes={{ root: classes[states[status]] }} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.userAction}>
                          <div>
                            <Link className={classes.link} to={`/kol/${_id}`}>
                              <VisibilityIcon 
                              style={{
                                marginTop: '1.5px',
                                fontSize: '36px'
                            }} 
                            className={classes.eyeIcon} />
                            </Link>
                          </div>
                          <div>
                            <Link className={classes.link}>
                              <DeleteIcon 
                              style={{
                                fontSize: '36px'
                            }}
                              onClick={() => handleDeleteClick(_id)} 
                              className={classes.deleteIcon} 
                              />
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {isLoading && (<div mx="auto"><CircularProgress  /></div>)} */}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalDocs > 0 && (<TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={3}
                      count={totalDocs}
                      rowsPerPage={params.limit}
                      page={params.page}
                      onChangePage={handleChangePage}
                      labelDisplayedRows={handleLabelDisplayedRows}
                    // SelectProps={{
                    //   inputProps: { 'aria-label': 'rows per page' },
                    //   native: true,
                    // }}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />)}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
