import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  TablePagination,
  TableContainer,
  TableFooter,
  CircularProgress,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  Link
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import * as moment from 'moment';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import ModalDetail from './components/modalDetail'

// service
import * as PaymentService from '../../services/payment.service';

// icon
import {
  VisibilityOutlined as VisibilityIcon
} from "@material-ui/icons";

// utils
import { formatMoney } from "../../utils";

// css
import { useStyles } from "./style";

const states = {
  APPROVED: "success",
  REJECTED: "secondary",
  PENDING: "warning"
};

export default function ListUser() {
  let timeout;
  const classes = useStyles();

  const modalDetailRef = useRef(null);

  const [listPayment, setListPayment] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDetailModal, setIsShowDetailModal] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false); // xác nhận user chọn 'APPROVE' hay 'REJECT'
  const [idSelected, setIdSelected] = useState('');
  const [status, setStatus] = useState('ALL');
  const [params, setParams] = useState({
    search: '',
    page: 0,
    limit: 8,
    status
  });

  useEffect(() => {
    getListPayment(params);
  }, [params])

  useEffect(() => {
    if(params.status !== status) {
      setParams({
        ...params,
        status,
        page: 0
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  async function getListPayment(params) {
    setIsLoading(true);
    const res = await PaymentService.list({
      ...params,
      page: params.page + 1
    }).catch();

    if (res) {
      setListPayment(res.docs || []);
      setTotalDocs(res.totalDocs || 0);
      setTotalPages(res.totalPages);
    }
    setIsLoading(false)
  };

  const handleChangePage = (event, newPage) => {
    setParams({
      ...params,
      page: newPage
    });
  };


  const handleLabelDisplayedRows = () => {
    return `Page ${params.page + 1} of ${totalPages}`;
  };

  const handelChangeSearch = (event) => {
    let text = event.target.value;
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setParams({
        ...params,
        search: text,
        page: 0
      })
    }, 1000)
  }

  // DETAIL MODAL
  const handleCloseDetailModal = (resetList) => {
    setIsShowDetailModal(false);
    setIdSelected('');

    if (resetList) getListPayment(params);
  }

  const handleShowDetailModal = async (_id) => {
    setIdSelected(_id);
    setIsShowDetailModal(true);
  }

  // CONFIRM MODAL
  const handleShowConfirmModal = (approve = false) => {
    setIsShowConfirmModal(true);
    setIsApproved(approve)
  }

  const handleCloseConfirmModal = () => {
    setIsShowConfirmModal(false)
    setIsApproved(false)
  }

  const handleConfirmModal = () => {
    handleCloseConfirmModal();
    modalDetailRef.current?.approvePayment(isApproved ? 'APPROVED' : 'REJECTED');
  }

  return (
    <>
      <Dialog
        className={classes.customDialog}
        open={isShowConfirmModal}
        onClose={handleCloseConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div>
            {
              isApproved ?
                "Approve this payment?"
                : "Reject this payment?"
            }
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {isApprove ? "Approve this booking?" : "Cancel this booking?"} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmModal} color="primary">
            No
          </Button>
          <Button
            // onClick={isApprove ? handleConfirmApproveBooking : handleConfirmCancelBooking}
            onClick={handleConfirmModal}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ModalDetail
        ref={modalDetailRef}
        _id={idSelected}
        isShow={isShowDetailModal}
        handleCloseDetailModal={handleCloseDetailModal}
        handleShowConfirmModal={handleShowConfirmModal}
        handleCloseConfirmModal={handleCloseConfirmModal}
      />

      <PageTitle title="Payment" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer component={Paper}>
              <div className={classes.coverTableHead}>
                <Input className={classes.inputSearch} placeholder="Search here..." onChange={handelChangeSearch} />
                <div 
                  style={{
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                {isLoading && (<div mx="auto"><CircularProgress /></div>)}
                <FormControl className={classes.filter} variant="outlined">
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    // size="small"
                    onChange={ 
                      event => { 
                        const { value } = event.target;
                        setStatus(value)
                      }
                    }
                  >
                    <MenuItem value={'ALL'}>ALL</MenuItem>
                    <MenuItem value={'PENDING'}>PENDING</MenuItem>
                    <MenuItem value={'APPROVED'}>APPROVED</MenuItem>
                    <MenuItem value={'REJECTED'}>REJECTED</MenuItem>
                  </Select>
                </FormControl>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '20%' }}>Fullname</TableCell>
                    <TableCell>Account number</TableCell>
                    <TableCell>Payment method</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {!isLoading && listPayment.length !== 0 && listPayment.map(({ _id, user, amount, currency, phone, type, status, createdAt }) => (
                    <TableRow key={_id}>
                      <TableCell style={{ width: '20%' }} className="pl-3 fw-normal">{user?.profile?.fullName || ''}</TableCell>
                      <TableCell className="pl-3 fw-normal">{phone}</TableCell>
                      <TableCell className="pl-3 fw-normal">{type} </TableCell>
                      <TableCell className="pl-3 fw-normal">{amount ? formatMoney(amount) : '0'} {currency}</TableCell>
                      <TableCell className="pl-3 fw-normal">{moment(createdAt).format("DD/MM/YYYY - HH:mm")}</TableCell>
                      <TableCell>
                        <Chip label={status} classes={{ root: classes[states[status]] }} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.userAction}>
                          <div>
                            <Link className={classes.link}>
                              <VisibilityIcon
                                style={{
                                  marginTop: '1.5px',
                                  fontSize: '36px'
                                }}
                                className={classes.eyeIcon}
                                onClick={() => handleShowDetailModal(_id)}
                              // onClick={() => {}}
                              />
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {isLoading && (<div mx="auto"><CircularProgress  /></div>)} */}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalDocs > 0 && (<TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={2}
                      count={totalDocs}
                      rowsPerPage={params.limit}
                      page={params.page}
                      onChangePage={handleChangePage}
                      labelDisplayedRows={handleLabelDisplayedRows}
                    // SelectProps={{
                    //   inputProps: { 'aria-label': 'rows per page' },
                    //   native: true,
                    // }}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />)}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
