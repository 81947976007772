import AxiosBase from './axiosClient';

export const uploadFile = async function(params) {

    let formData = new FormData();
    if(params.file)
    formData.append("file", params.file);

    if(params.files)
    formData.append("files", params.files);

    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.post('cms/uploadImages', formData, 'multipart/form-data');
    return res.data;
}