export const AVATAR_DEFAULT = '/public-images/default/default-avatar.jpeg';
export const IMAGE_DEFAULT = '/public-images/default/default-image.jpeg';
export const TICK_VERIFIED = '/public-images/default/tich-xanh.png';
export const CANCEL_SIGN = '/public-images/default/cancellation-sign.png';

export const HOST_IMAGE = 'https://bookingkol.vn/';
// export const HOST_IMAGE = 'http://localhost:5000/'

export const LITERACY = [
    'Tiểu học',
    'Trung học cơ sở',
    'Trung học phổ thông',
    'Trung cấp nghề',
    'Cao đẳng',
    'Đại học',
    'Cao học'
];

export const RELATIONSHIP = [
    'Độc thân',
    'Đã đính hôn',
    'Đã có gia đình',
    'Đã ly dị'
];