import React, { useState } from "react";
import {
  LinearProgress,
  Grid
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import * as moment from 'moment';
import MomentUtils from '@date-io/moment';

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  var [params, setParams] = useState({
    fromDate: moment().subtract(1, 'month'),
    toDate: moment()
  });

  return (
    <>
      <PageTitle
        title="Báo cáo tình hình hoạt động của website, ứng dụng"
        custom={
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              style={{ margin: '0px 15px' }}
              disableFuture
              openTo="date"
              format="DD/MM/YYYY"
              label="From"
              // views={["year", "month", "date"]}
              value={params.fromDate}
              onChange={date => {
                setParams({
                  ...params,
                  fromDate: date
                })
              }}
            />
            <DatePicker
              style={{ margin: '0px 15px' }}
              disableFuture
              openTo="date"
              format="DD/MM/YYYY"
              label="To"
              // views={["year", "month", "date"]}
              value={params.toDate}
              onChange={date => {
                setParams({
                  ...params,
                  toDate: date
                })
              }}
            />
          </MuiPickersUtilsProvider>}
      />
      <Grid container spacing={6} style={{ marginTop: "60px" }}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title=""
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            noBodyPadding
            noHeaderPadding
          >
            <Grid
              container
            >
              <Grid item style={{ backgroundColor: "#fac7ce", height: "200px" }} xs={1}>
                {/* <div style={{ width: "100%", height: "100%", backgroundColor: "#fac7ce" }}></div> */}
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.visitsNumberContainer}>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className={classes.divAmount}>
                        <span className={classes.spanAmount}>
                          11000
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                        Số lượng truy cập
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            title=""
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            noBodyPadding
            noHeaderPadding
          >
            <Grid
              container
            >
              <Grid item style={{ backgroundColor: "#fac7ce", height: "200px" }} xs={1}>
                {/* <div style={{ width: "100%", height: "100%", backgroundColor: "#fac7ce" }}></div> */}
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitleSeller}>
                      <div style={{width: "50%"}}>
                        <span style={{color: "#e38945"}} className={classes.spanTitleSeller}>Tổng số người bán</span>
                      </div>
                      <div style={{width: "50%"}}>
                        <span style={{color: "#79aa5a"}} className={classes.spanTitleSeller}>Số lượng người bán mới</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.visitsNumberContainer}>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className={classes.divAmountSeller}>
                        <div style={{ width: "50%", borderRight: "3px solid grey" }}>
                          <span style={{color: "#e38945"}} className={classes.spanAmountSeller}>
                            100
                          </span>
                        </div>
                        <div style={{ width: "50%" }}>
                          <span style={{color: "#79aa5a"}} className={classes.spanAmountSeller}>
                            5
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                        Số lượng người bán
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            title=""
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            noBodyPadding
            noHeaderPadding
          >
            <Grid
              container
            >
              <Grid item style={{ backgroundColor: "#fac7ce", height: "200px" }} xs={1}>
                {/* <div style={{ width: "100%", height: "100%", backgroundColor: "#fac7ce" }}></div> */}
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitleSeller}>
                      <div style={{width: "50%"}}>
                        <span style={{color: "#e38945"}} className={classes.spanTitleSeller}>Tổng số sản phẩm</span>
                      </div>
                      <div style={{width: "50%"}}>
                        <span style={{color: "#79aa5a"}} className={classes.spanTitleSeller}>Số sản phẩm đăng bán mới</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.visitsNumberContainer}>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className={classes.divAmountSeller}>
                        <div style={{ width: "50%", borderRight: "3px solid grey" }}>
                          <span style={{color: "#e38945"}} className={classes.spanAmountSeller}>
                            100
                          </span>
                        </div>
                        <div style={{ width: "50%" }}>
                          <span style={{color: "#79aa5a"}} className={classes.spanAmountSeller}>
                            5
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                        Số lượng sản phẩm
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      <Grid container spacing={6} style={{ marginTop: "60px" }}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title=""
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            noBodyPadding
            noHeaderPadding
          >
            <Grid
              container
            >
              <Grid item style={{ backgroundColor: "#fac7ce", height: "200px" }} xs={1}>
                {/* <div style={{ width: "100%", height: "100%", backgroundColor: "#fac7ce" }}></div> */}
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.visitsNumberContainer}>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className={classes.divAmount}>
                        <span className={classes.spanAmount}>
                          1.500.000.000
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                        Tổng giá trị giao dịch
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Widget
            title=""
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            noBodyPadding
            noHeaderPadding
          >
            <Grid
              container
            >
              <Grid item style={{ backgroundColor: "#fac7ce", height: "200px" }} xs={1}>
                {/* <div style={{ width: "100%", height: "100%", backgroundColor: "#fac7ce" }}></div> */}
              </Grid>
              <Grid item xs={11}>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitleSeller}>
                      <div style={{ width: "30%"}}>
                        <span style={{color: "#e38945"}} className={classes.spanTitleSeller}>Tổng số đơn hàng</span>
                      </div>
                      <div style={{paddingTop: "20px", width: "30%"}}>
                        <span style={{color: "#79aa5a"}} className={classes.spanTitleSeller}>Tổng số đơn hàng thành công</span>
                      </div>
                      <div style={{ paddingTop: "20px",width: "30%"}}>
                        <span style={{color: "#b22225"}} className={classes.spanTitleSeller}>Tổng số đơn hàng không thành công</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.visitsNumberContainer}>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className={classes.divAmountSeller}>
                        <div style={{  width: "50%", borderRight: "3px solid grey" }}>
                          <span style={{color: "#e38945"}} className={classes.spanAmountSeller}>
                            150
                          </span>
                        </div>
                        <div style={{ width: "50%", borderRight: "3px solid grey" }}>
                          <span style={{color: "#79aa5a"}} className={classes.spanAmountSeller}>
                            100
                          </span>
                        </div>
                        <div style={{ width: "50%" }}>
                          <span style={{color: "#b22225"}} className={classes.spanAmountSeller}>
                            50
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                // style={{ height: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.divTitle}>
                      <span className={classes.spanTitle}>
                        Số lượng giao dịch
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
