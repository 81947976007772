import AxiosBase from './axiosClient';

export const list = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`areas-of-concern/list`);
    return res.data;
}

// export const getDetail = async function(params) {
//     const axios = new AxiosBase();
//     axios.createAxios();
//     const res = await axios.get(`cms/user/${params.userId}`);
//     return res.data;
// }
