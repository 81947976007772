import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  TablePagination,
  TableContainer,
  TableFooter,
  CircularProgress,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import * as moment from 'moment';
import MomentUtils from '@date-io/moment';

// components
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";

// service
import * as TransactionService from '../../services/transaction.service';

// icon
import {
  VisibilityOutlined as VisibilityIcon
} from "@material-ui/icons";

// utils
import { formatMoney } from "../../utils";

// css
import { useStyles } from "./style";

const states = {
  // APPROVED: "success",
  // REJECTED: "secondary",
  // PENDING: "warning"
  '+1': 'success',
  '-1': 'warning',
  '-2': 'warning',
  '-3': 'warning',
  '+2': 'success',
  '+3': 'success',
  '-4': 'warning',
  '+4': 'success',
  '-5': 'warning',
};

export default function ListUser() {
  let timeout;
  const classes = useStyles();

  const [listWithdraw, setListWithdraw] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    limit: 8,
    fromDate: moment().subtract(1, 'year'),
    toDate: moment()
  });

  useEffect(() => {
    // if(params.fromDate && params.fromDate) {
      getListTransaction(params);
    // } else {
    //   getListTransaction({
    //     ...params,
    //     fromDate: '',
    //     toDate: ''
    //   });
    // }
  }, [params])

  async function getListTransaction(params) {
    setIsLoading(true);
    const res = await TransactionService.list({
      ...params,
      page: params.page + 1
    }).catch();

    if (res) {
      setListWithdraw(res.docs || []);
      setTotalDocs(res.totalDocs || 0);
      setTotalPages(res.totalPages);
    }
    setIsLoading(false)
  };

  const handleChangePage = (event, newPage) => {
    setParams({
      ...params,
      page: newPage
    });
  };


  const handleLabelDisplayedRows = () => {
    return `Page ${params.page + 1} of ${totalPages}`;
  };

  const handleChangeFromDate = (date) => {
    if (date) {
      setParams({
        ...params,
        fromDate: date
      })
      return;
    }
  }

  const handleChangeToDate = (date) => {
    if (date) {
      setParams({
        ...params,
        toDate: date
      })
      return;
    }
  }

  const renderType = type => {

    switch (type) {
      case '+1': return 'Nạp tiền';
      case '-1': return 'Phí giao dịch';
      case '-2': return 'Phí cọc cho hệ thống';
      case '-3': return 'Trả tiền thuê KOL';
      case '+2': return 'KOL nhận thanh toán';
      case '+3': return 'Hoàn tiền Booking';
      case '-4': return 'Rút tiền';
      case '+4': return 'Hoàn lại tiền rút';
      case '-5': return 'Phí rút tiền';
      default: return ''
    }
  }

  return (
    <>
      <PageTitle title="Transaction" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer component={Paper}>
              <div className={classes.coverTableHead}>
                {/* <Input className={classes.inputSearch} placeholder="Search here..." onChange={handelChangeSearch} /> */}
                <div
                  style={{
                    // width: '200px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    // alignItems: 'center'
                  }}
                >
                  {isLoading && (
                  <div mx="auto" className={classes.iconLoading} >
                    <CircularProgress />
                    </div>
                    )}
                  <div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        style={{margin: '0px 15px'}}
                        disableFuture
                        openTo="date"
                        format="DD/MM/YYYY"
                        label="From"
                        // views={["year", "month", "date"]}
                        value={params.fromDate}
                        onChange={handleChangeFromDate}
                      />
                      <DatePicker
                        style={{margin: '0px 15px'}}
                        disableFuture
                        openTo="date"
                        format="DD/MM/YYYY"
                        label="To"
                        // views={["year", "month", "date"]}
                        value={params.toDate}
                        onChange={handleChangeToDate}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '20%' }}>Fullname</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Before Balance</TableCell>
                    <TableCell>After Balance</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {!isLoading && listWithdraw.length !== 0 && listWithdraw.map(({ _id, user, amount, beforeBalance, afterBalance, type, createdAt }) => (
                    <TableRow key={_id}>
                      <TableCell style={{ width: '20%' }} className="pl-3 fw-normal">{user?.profile?.fullName || ''}</TableCell>
                      <TableCell className="pl-3 fw-normal">{amount ? formatMoney(amount) : '0'}</TableCell>
                      <TableCell className="pl-3 fw-normal">{amount ? formatMoney(beforeBalance) : '0'}</TableCell>
                      <TableCell className="pl-3 fw-normal">{amount ? formatMoney(afterBalance) : '0'}</TableCell>
                      <TableCell className="pl-3 fw-normal">
                        <Chip label={renderType(type)} classes={{ root: classes['warning'] }} />
                      </TableCell>
                      <TableCell className="pl-3 fw-normal">{moment(createdAt).format("DD/MM/YYYY - HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                  {/* {isLoading && (<div mx="auto"><CircularProgress  /></div>)} */}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalDocs > 0 && (<TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={3}
                      count={totalDocs}
                      rowsPerPage={params.limit}
                      page={params.page}
                      onChangePage={handleChangePage}
                      labelDisplayedRows={handleLabelDisplayedRows}
                    // SelectProps={{
                    //   inputProps: { 'aria-label': 'rows per page' },
                    //   native: true,
                    // }}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />)}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
