import AxiosBase from './axiosClient';

export const listPhoneSetting = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/setting/list-phone-setting`);
    return res.data;
}

export const deletePhoneSetting = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.delete(`cms/setting/delete-phone-setting/${params.settingId}`);
    return res.data;
}

export const addPhoneSetting = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.post(`cms/setting/add-phone-setting`, params);
    return res.data;
}
