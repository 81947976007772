import AxiosBase from './axiosClient';

export const listPhone = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`country/phone`);
    return res.data;
}

export const listProvince = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`province/list`);
    return res.data;
}

export const listDistrict = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`district/list?provinceId=${params.provinceId}`);
    return res.data;
}

export const listWard = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`ward/list?districtId=${params.districtId}`);
    return res.data;
}