/* eslint-disable */
import axios from 'axios';
import { sendNotification } from '../components/Notification'
import errorFile from '../lang/error.json';
// import { showNotify } from '../utils/showNotify';
// import queryString from 'query-string';
// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#requestconfig` for the full list of configs

const baseUrl = process.env.REACT_APP_HOST;
const baseAxiosConfig = {
  baseURL: baseUrl,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },

};

export function getAccessToken() {
  if (localStorage) {
    return localStorage.getItem('kol_token');
  }
  return '';
}

export function getLang() {
  if (localStorage) {
    return localStorage.getItem('lang') || 'en';
  }
  return 'en';
}

let errors = {
  USER_NOT_FOUND: 'User not found',
  EMAIL_OR_PASSWORD_NOT_MATCH: 'Username or password is incorrect',
  ACCESS_DENIED: 'Access denied'
};

class Service {
  // instance = null;
  // eslint-disable
  createAxios = (url, isAuthorization = true) => {
    const config = { ...baseAxiosConfig };
    const token = getAccessToken();
    if (url) {
      config.baseURL = url;
    }

    if (token) {
      config.headers['api-token'] = `${token}`;
    }

    if (isAuthorization) {
      config.headers.authorization = `${token}`;
    }

    this.instance = axios.create(config);


    this.instance.interceptors.response.use(
      response => {
        const res = response && response.data;
        // console.log(res)

        if (res.code === 1) {
          return res;
        }

        if (res.error === 'ACCESS_DENIED' || res.error === 'TOKEN_INVALID') {
          localStorage.removeItem('kol_token');
          window.location.replace('/login');
        }

        const message = res.error;
        const lang = getLang();
        sendNotification({
          type: "error",
          message: errorFile &&
          errorFile[lang] &&
          errorFile[lang][message]
          || "Something went wrong!! Please try again",
          color: "error"
        })
        return res;
      },
      error => {
        //redirect login
        console.log(error?.response?.data)
        if (
          error?.response?.data?.message === 'ACCESS_DENIED' ||
          error?.response?.data?.message === 'TOKEN_INVALID' ||
          error?.response?.data?.message === 'jwt expired'
        ) {
          localStorage.removeItem('kol_token');
          window.location.replace('/login');
          return;
        }

        const lang = getLang();
        sendNotification({
          type: "error",
          message: errorFile &&
          errorFile[lang] &&
          errorFile[lang][error?.response?.data?.message]
          || "Something went wrong!! Please try again",
          color: "error"
        })
        return Promise.reject(error);
      },
    );
  };

  get = async (apiURL, params) => {
    const resp = await this.instance.get(`/${apiURL}`, { params });
    return resp;
  };

  post = async (apiURL, params, contentType = 'application/json') => {
    const resp = await this.instance.post(`/${apiURL}`, params, {
      'Content-Type': contentType,
    });
    return resp;
  };

  put = async (apiURL, params, contentType) => {
    const resp = await this.instance.put(`/${apiURL}`, params, {
      'Content-Type': contentType,
    });
    return resp;
  };

  patch = async (apiURL, params, contentType) => {
    const resp = await this.instance.patch(`/${apiURL}`, params, {
      'Content-Type': contentType,
    });
    return resp;
  };

  delete = async (apiURL, params, contentType) => {
    const resp = await this.instance.delete(`/${apiURL}`, {
      'Content-Type': contentType,
      data: { ...params },
    });
    return resp;
  };
}

export default Service;

// const axiosClient = axios.create({
//   baseURL: process.env.REACT_APP_HOST,
//   headers: {
//     'content-type': 'application/json',

//   },
//   paramsSerializer: params => queryString.stringify(params),
// });

// axiosClient.interceptors.response.use((response) => {
//   if (response && response.data) {
//     return response.data;
//   }
//   return response;
// }, (error) => {
//   // Handle errors
//   throw error;
// });
// export default axiosClient;

