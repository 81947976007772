import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  formDetail: {
    // borderColor: '1px',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    width: '650px',
    margin: '0 auto 70px auto',
    // paddingLeft: '50px',
    // paddingRight: '50px',
  },
  divSaveData: {
    position: 'fixed',
    width: '100%',
    height: '65px',
    backgroundColor: 'white',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: '0px 0px 5px #aaaaaa',
    'z-index': 10,
    '& Button': {
      height: '46px',
      width: '100px',
      marginRight: '200px'
    }
  },
  iconAddPhoneSetting: {
    cursor: "pointer"
  },
  tableContainer: {
    '& .MuiTable-root': {
      '& .MuiTableBody-root': {
        "& .MuiTableRow-root:last-child td": {
          // borderTop: '1px solid rgba(224, 224, 224, .5)',
          borderBottom: 0
        }
      }
    }
  }
}))

