import AxiosBase from './axiosClient';

export const list = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(
        `cms/user/list?page=${params.page}&limit=${params.limit}&status=${params.status}&type=${params.type}&search=${params.search}`
        );
    return res.data;
}

export const getDetail = async function(params) {
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.get(`cms/user/${params.userId}?type=${params.type}`);
    return res.data;
}

export const updateInfo = async function(params) {
    const {_id, ...body} = params;
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/user/${_id}`, body);
    return res.data;
}

export const deleteKol = async function(params) {
    const {_id} = params;
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.delete(`cms/user/${_id}`);
    return res.data;
}

export const approveIdCard = async function(params) {
    const {_id, ...body} = params;
    const axios = new AxiosBase();
    axios.createAxios();
    const res = await axios.put(`cms/user/approve-id-card/${_id}`, body);
    return res.data;
}