import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useTheme } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';
// components
import Layout from "./Layout";

// pages
import Login from "../pages/login";

// context
import { useAdminState, useAdminDispatch } from "../context/AdminContext";

// services
import * as adminService from "../services/admin.service";

// css
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
  toastsContainer: {
    marginTop: theme.spacing(6),
    right: 0,
    paddingRight: 40,
    width: "auto"
  }
}));

export default function App() {
  // global
  document.title = 'Booking KOL Admin';
  const { isAuthenticated } = useAdminState();
  const [isWaiting, setIsWaiting] = useState(true);
  const dispatch = useAdminDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    if (!isAuthenticated) {
      getCurrentUser();
    } else {
      setIsWaiting(false);
    }
  }, [])


  async function getCurrentUser() {
    const token = localStorage.getItem('kol_token');
    if (token) {
      const res = await adminService.getCurrentUser();

      if(res) {
        const data = {
          id: res._id,
          email: res.email,
          phone: res.profile?.phone,
          fullName: res.profile?.fullName,
        };
        dispatch({ type: "SET_CURRENT_USER", payload: data });
      }
    }
    setIsWaiting(false);
  }

  return !isWaiting && (
    <div>
      <ToastContainer
        hideProgressBar={false}
        className={classes.toastsContainer}
        closeButton={false}
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
      />
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          {/* <Route
            exact
            path="/app"
            render={() => <Redirect to="/dashboard" />}
          /> */}
          {isAuthenticated ?
            (<PrivateRoute path="/" component={Layout} />) :
            (<PublicRoute path="/login" component={Login} />)
          }
          <Route path="**" render={() => <Redirect to="/login" />} />
          {/* <Route component={Error} /> */}
        </Switch>
      </Router>
    </div>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => React.createElement(component, props) }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => React.createElement(component, props) }
      />
    );
  }
}
